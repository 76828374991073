*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "proxima-nova", Avenir, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #252525;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #fb6142;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #d32704;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #888888;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.6875rem; }

h3, .h3 {
  font-size: 1.25rem; }

h4, .h4 {
  font-size: 1.0625rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 400; }

.display-1 {
  font-size: 6rem;
  font-weight: 400;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 400;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.270588); }

small,
.small,
.cb-text-sm {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #888888; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #888888; }

.container, .cb-container-fixed {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, .cb-container-fixed {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, .cb-container-fixed {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, .cb-container-fixed {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, .cb-container-fixed {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .c-Box__bodyRow {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .cb-form-col-label, .cb-form-col-input, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .p-Proposal__formBox__label, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .p-Proposal__formBox__main, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%; }

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%; }

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%; }

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%; }

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%; }

.col-12, .cb-form-col-label, .cb-form-col-input {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333333%; }

.offset-2 {
  margin-left: 16.66666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333333%; }

.offset-5 {
  margin-left: 41.66666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333333%; }

.offset-8 {
  margin-left: 66.66666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333333%; }

.offset-11 {
  margin-left: 91.66666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333333%; }
  .offset-sm-2 {
    margin-left: 16.66666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333333%; }
  .offset-sm-5 {
    margin-left: 41.66666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333333%; }
  .offset-sm-8 {
    margin-left: 66.66666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333333%; }
  .offset-sm-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333333%; }
  .offset-md-2 {
    margin-left: 16.66666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333333%; }
  .offset-md-5 {
    margin-left: 41.66666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333333%; }
  .offset-md-8 {
    margin-left: 66.66666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333333%; }
  .offset-md-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 992px) {
  .col-lg, .cb-form-col-input {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-lg-2, .p-Proposal__formBox__label {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-lg-3, .cb-form-col-label {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10, .p-Proposal__formBox__main {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333333%; }
  .offset-lg-2 {
    margin-left: 16.66666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333333%; }
  .offset-lg-5 {
    margin-left: 41.66666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333333%; }
  .offset-lg-8 {
    margin-left: 66.66666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333333%; }
  .offset-lg-11 {
    margin-left: 91.66666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%; }
  .col-xl-2, .cb-form-col-label {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%; }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%; }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%; }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333333%; }
  .offset-xl-2 {
    margin-left: 16.66666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333333%; }
  .offset-xl-5 {
    margin-left: 41.66666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333333%; }
  .offset-xl-8 {
    margin-left: 66.66666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333333%; }
  .offset-xl-11 {
    margin-left: 91.66666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #888888; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #888888; }
  .table tbody + tbody {
    border-top: 2px solid #888888; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #888888; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #888888; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.0235294); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.0235294); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fed3ca; }

.table-hover .table-primary:hover {
  background-color: #febeb1; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #febeb1; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d8dbdf; }

.table-hover .table-secondary:hover {
  background-color: #caced4; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #caced4; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d4e9c6; }

.table-hover .table-success:hover {
  background-color: #c6e2b4; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c6e2b4; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d8dbdf; }

.table-hover .table-info:hover {
  background-color: #caced4; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #caced4; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbeabf; }

.table-hover .table-warning:hover {
  background-color: #fae2a7; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fae2a7; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ebcccc; }

.table-hover .table-danger:hover {
  background-color: #e4baba; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e4baba; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd; }

.table-hover .table-light:hover {
  background-color: #f0f0f0; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f0f0f0; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cacaca; }

.table-hover .table-dark:hover {
  background-color: #bdbdbd; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bdbdbd; }

.table-primary-darkest,
.table-primary-darkest > th,
.table-primary-darkest > td {
  background-color: #fac4b9; }

.table-hover .table-primary-darkest:hover {
  background-color: #f8b0a1; }
  .table-hover .table-primary-darkest:hover > td,
  .table-hover .table-primary-darkest:hover > th {
    background-color: #f8b0a1; }

.table-primary-gray-light,
.table-primary-gray-light > th,
.table-primary-gray-light > td {
  background-color: #fefcfc; }

.table-hover .table-primary-gray-light:hover {
  background-color: #f8e9e9; }
  .table-hover .table-primary-gray-light:hover > td,
  .table-hover .table-primary-gray-light:hover > th {
    background-color: #f8e9e9; }

.table-accent,
.table-accent > th,
.table-accent > td {
  background-color: #fbeabf; }

.table-hover .table-accent:hover {
  background-color: #fae2a7; }
  .table-hover .table-accent:hover > td,
  .table-hover .table-accent:hover > th {
    background-color: #fae2a7; }

.table-medium,
.table-medium > th,
.table-medium > td {
  background-color: #ececec; }

.table-hover .table-medium:hover {
  background-color: #dfdfdf; }
  .table-hover .table-medium:hover > td,
  .table-hover .table-medium:hover > th {
    background-color: #dfdfdf; }

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #dedede; }

.table-hover .table-gray:hover {
  background-color: #d1d1d1; }
  .table-hover .table-gray:hover > td,
  .table-hover .table-gray:hover > th {
    background-color: #d1d1d1; }

.table-tan,
.table-tan > th,
.table-tan > td {
  background-color: #fcfcfc; }

.table-hover .table-tan:hover {
  background-color: #efefef; }
  .table-hover .table-tan:hover > td,
  .table-hover .table-tan:hover > th {
    background-color: #efefef; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.0235294); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.0235294); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.0235294); }

.table .thead-dark th {
  color: white;
  background-color: #fb6142;
  border-color: #e0e0e0; }

.table .thead-light th {
  color: #404040;
  background-color: #f6f6f6;
  border-color: #888888; }

.table-dark {
  color: white;
  background-color: #fb6142; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #e0e0e0; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #575757;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d0d0d0;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #575757;
    background-color: #fff;
    border-color: #fec9bf;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(251, 97, 66, 0.25); }
  .form-control::placeholder {
    color: #888888;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #eee;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #575757;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.c-TitleBar__actionButton,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.c-TitleBar__actionButton, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.c-TitleBar__actionButton,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.c-TitleBar__actionButton {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .c-TitleBar__actionButton,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .c-TitleBar__actionButton {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.c-TitleBar__actionButton:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.c-TitleBar__actionButton:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .c-TitleBar__actionButton,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .c-TitleBar__actionButton {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.c-TitleBar__actionButton:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.c-TitleBar__actionButton:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #888888; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #66b035; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(102, 176, 53, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #66b035; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #66b035;
    box-shadow: 0 0 0 0.2rem rgba(102, 176, 53, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #66b035; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #66b035; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #aadb89; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background: #7fca4e linear-gradient(180deg, #93d269, #7fca4e) repeat-x; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(102, 176, 53, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #66b035; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(102, 176, 53, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #b94a48; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(185, 74, 72, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #b94a48; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #b94a48;
    box-shadow: 0 0 0 0.2rem rgba(185, 74, 72, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #b94a48; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #b94a48; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #dca5a4; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background: #c76e6d linear-gradient(180deg, #cf8483, #c76e6d) repeat-x; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(185, 74, 72, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #b94a48; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(185, 74, 72, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .c-TitleBar__actionButton {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .c-TitleBar__actionButton:hover, .btn:focus, .c-TitleBar__actionButton:focus {
    text-decoration: none; }
  .btn:focus, .c-TitleBar__actionButton:focus, .btn.focus, .focus.c-TitleBar__actionButton {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(251, 97, 66, 0.25); }
  .btn.disabled, .disabled.c-TitleBar__actionButton, .btn:disabled, .c-TitleBar__actionButton:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled), .c-TitleBar__actionButton:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .c-TitleBar__actionButton:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active, .c-TitleBar__actionButton:not(:disabled):not(.disabled).active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn:not(:disabled):not(.disabled):active:focus, .c-TitleBar__actionButton:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .c-TitleBar__actionButton:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(251, 97, 66, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled, a.disabled.c-TitleBar__actionButton,
fieldset:disabled a.btn,
fieldset:disabled a.c-TitleBar__actionButton {
  pointer-events: none; }

.btn-primary {
  color: white;
  background: #fb6142 linear-gradient(180deg, #fc795e, #fb6142) repeat-x;
  border-color: #fb6142;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: white;
    background: #fa421d linear-gradient(180deg, #fb5e3f, #fa421d) repeat-x;
    border-color: #fa3710; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(251, 97, 66, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: white;
    background-color: #fb6142;
    border-color: #fb6142; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: white;
    background-color: #fa3710;
    background-image: none;
    border-color: #f82e05; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(251, 97, 66, 0.5); }

.btn-secondary {
  color: white;
  background: #73808e linear-gradient(180deg, #88939f, #73808e) repeat-x;
  border-color: #73808e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: white;
    background: #626d79 linear-gradient(180deg, #79838d, #626d79) repeat-x;
    border-color: #5c6772; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(115, 128, 142, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: white;
    background-color: #73808e;
    border-color: #73808e; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: white;
    background-color: #5c6772;
    background-image: none;
    border-color: #56606b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(115, 128, 142, 0.5); }

.btn-success {
  color: white;
  background: #66b035 linear-gradient(180deg, #7dbc53, #66b035) repeat-x;
  border-color: #66b035;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: white;
    background: #55932c linear-gradient(180deg, #6ea34c, #55932c) repeat-x;
    border-color: #4f8929; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(102, 176, 53, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: white;
    background-color: #66b035;
    border-color: #66b035; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: white;
    background-color: #4f8929;
    background-image: none;
    border-color: #4a7f26; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(102, 176, 53, 0.5); }

.btn-info {
  color: white;
  background: #73808e linear-gradient(180deg, #88939f, #73808e) repeat-x;
  border-color: #73808e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: white;
    background: #626d79 linear-gradient(180deg, #79838d, #626d79) repeat-x;
    border-color: #5c6772; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(115, 128, 142, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: white;
    background-color: #73808e;
    border-color: #73808e; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: white;
    background-color: #5c6772;
    background-image: none;
    border-color: #56606b; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(115, 128, 142, 0.5); }

.btn-warning {
  color: #404040;
  background: #efb519 linear-gradient(180deg, #f1c03c, #efb519) repeat-x;
  border-color: #efb519;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: white;
    background: #d39e0f linear-gradient(180deg, #daac33, #d39e0f) repeat-x;
    border-color: #c7950e; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(239, 181, 25, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #404040;
    background-color: #efb519;
    border-color: #efb519; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: white;
    background-color: #c7950e;
    background-image: none;
    border-color: #bb8c0d; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(239, 181, 25, 0.5); }

.btn-danger {
  color: white;
  background: #b94a48 linear-gradient(180deg, #c46563, #b94a48) repeat-x;
  border-color: #b94a48;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: white;
    background: #9e3e3d linear-gradient(180deg, #ad5b5a, #9e3e3d) repeat-x;
    border-color: #953b39; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(185, 74, 72, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: white;
    background-color: #b94a48;
    border-color: #b94a48; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: white;
    background-color: #953b39;
    background-image: none;
    border-color: #8c3735; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(185, 74, 72, 0.5); }

.btn-light {
  color: #404040;
  background: #f9f9f9 linear-gradient(180deg, #fafafa, #f9f9f9) repeat-x;
  border-color: #f9f9f9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #404040;
    background: #e6e6e6 linear-gradient(180deg, #eaeaea, #e6e6e6) repeat-x;
    border-color: #e0dfdf; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #404040;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #404040;
    background-color: #e0dfdf;
    background-image: none;
    border-color: #d9d9d9; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }

.btn-dark {
  color: white;
  background: #404040 linear-gradient(180deg, #5d5d5d, #404040) repeat-x;
  border-color: #404040;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: white;
    background: #2d2d2d linear-gradient(180deg, #4c4c4c, #2d2d2d) repeat-x;
    border-color: #272626; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: white;
    background-color: #404040;
    border-color: #404040; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: white;
    background-color: #272626;
    background-image: none;
    border-color: #202020; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }

.btn-primary-darkest {
  color: white;
  background: #ec2c05 linear-gradient(180deg, #ee4b2a, #ec2c05) repeat-x;
  border-color: #ec2c05;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary-darkest:hover {
    color: white;
    background: #c62504 linear-gradient(180deg, #cf452a, #c62504) repeat-x;
    border-color: #ba2204; }
  .btn-primary-darkest:focus, .btn-primary-darkest.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(236, 44, 5, 0.5); }
  .btn-primary-darkest.disabled, .btn-primary-darkest:disabled {
    color: white;
    background-color: #ec2c05;
    border-color: #ec2c05; }
  .btn-primary-darkest:not(:disabled):not(.disabled):active, .btn-primary-darkest:not(:disabled):not(.disabled).active,
  .show > .btn-primary-darkest.dropdown-toggle {
    color: white;
    background-color: #ba2204;
    background-image: none;
    border-color: #ad2004; }
    .btn-primary-darkest:not(:disabled):not(.disabled):active:focus, .btn-primary-darkest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-darkest.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(236, 44, 5, 0.5); }

.btn-primary-gray-light {
  color: #404040;
  background: #faf6f5 linear-gradient(180deg, #fbf7f7, #faf6f5) repeat-x;
  border-color: #faf6f5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary-gray-light:hover {
    color: #404040;
    background: #eedfdb linear-gradient(180deg, #f0e3e1, #eedfdb) repeat-x;
    border-color: #ead7d3; }
  .btn-primary-gray-light:focus, .btn-primary-gray-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(250, 246, 245, 0.5); }
  .btn-primary-gray-light.disabled, .btn-primary-gray-light:disabled {
    color: #404040;
    background-color: #faf6f5;
    border-color: #faf6f5; }
  .btn-primary-gray-light:not(:disabled):not(.disabled):active, .btn-primary-gray-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-gray-light.dropdown-toggle {
    color: #404040;
    background-color: #ead7d3;
    background-image: none;
    border-color: #e6cfca; }
    .btn-primary-gray-light:not(:disabled):not(.disabled):active:focus, .btn-primary-gray-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-gray-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(250, 246, 245, 0.5); }

.btn-accent {
  color: #404040;
  background: #efb519 linear-gradient(180deg, #f1c03c, #efb519) repeat-x;
  border-color: #efb519;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-accent:hover {
    color: white;
    background: #d39e0f linear-gradient(180deg, #daac33, #d39e0f) repeat-x;
    border-color: #c7950e; }
  .btn-accent:focus, .btn-accent.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(239, 181, 25, 0.5); }
  .btn-accent.disabled, .btn-accent:disabled {
    color: #404040;
    background-color: #efb519;
    border-color: #efb519; }
  .btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active,
  .show > .btn-accent.dropdown-toggle {
    color: white;
    background-color: #c7950e;
    background-image: none;
    border-color: #bb8c0d; }
    .btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-accent.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(239, 181, 25, 0.5); }

.btn-medium {
  color: #404040;
  background: #bababa linear-gradient(180deg, #c4c4c4, #bababa) repeat-x;
  border-color: #bababa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-medium:hover {
    color: #404040;
    background: #a7a7a7 linear-gradient(180deg, #b4b4b4, #a7a7a7) repeat-x;
    border-color: #a1a0a0; }
  .btn-medium:focus, .btn-medium.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(186, 186, 186, 0.5); }
  .btn-medium.disabled, .btn-medium:disabled {
    color: #404040;
    background-color: #bababa;
    border-color: #bababa; }
  .btn-medium:not(:disabled):not(.disabled):active, .btn-medium:not(:disabled):not(.disabled).active,
  .show > .btn-medium.dropdown-toggle {
    color: #404040;
    background-color: #a1a0a0;
    background-image: none;
    border-color: #9a9a9a; }
    .btn-medium:not(:disabled):not(.disabled):active:focus, .btn-medium:not(:disabled):not(.disabled).active:focus,
    .show > .btn-medium.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(186, 186, 186, 0.5); }

.btn-gray {
  color: white;
  background: #888888 linear-gradient(180deg, #9a9a9a, #888888) repeat-x;
  border-color: #888888;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-gray:hover {
    color: white;
    background: #757575 linear-gradient(180deg, #8a8a8a, #757575) repeat-x;
    border-color: #6f6e6e; }
  .btn-gray:focus, .btn-gray.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(136, 136, 136, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: white;
    background-color: #888888;
    border-color: #888888; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: white;
    background-color: #6f6e6e;
    background-image: none;
    border-color: #686868; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(136, 136, 136, 0.5); }

.btn-tan {
  color: #404040;
  background: #f6f6f6 linear-gradient(180deg, #f7f7f7, #f6f6f6) repeat-x;
  border-color: #f6f6f6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-tan:hover {
    color: #404040;
    background: #e3e3e3 linear-gradient(180deg, #e7e7e7, #e3e3e3) repeat-x;
    border-color: #dddcdc; }
  .btn-tan:focus, .btn-tan.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(246, 246, 246, 0.5); }
  .btn-tan.disabled, .btn-tan:disabled {
    color: #404040;
    background-color: #f6f6f6;
    border-color: #f6f6f6; }
  .btn-tan:not(:disabled):not(.disabled):active, .btn-tan:not(:disabled):not(.disabled).active,
  .show > .btn-tan.dropdown-toggle {
    color: #404040;
    background-color: #dddcdc;
    background-image: none;
    border-color: #d6d6d6; }
    .btn-tan:not(:disabled):not(.disabled):active:focus, .btn-tan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tan.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(246, 246, 246, 0.5); }

.btn-outline-primary, .cb-btn-outline-admin {
  color: #fb6142;
  background-color: transparent;
  background-image: none;
  border-color: #fb6142; }
  .btn-outline-primary:hover, .cb-btn-outline-admin:hover {
    color: white;
    background-color: #fb6142;
    border-color: #fb6142; }
  .btn-outline-primary:focus, .cb-btn-outline-admin:focus, .btn-outline-primary.focus, .focus.cb-btn-outline-admin {
    box-shadow: 0 0 0 0.2rem rgba(251, 97, 66, 0.5); }
  .btn-outline-primary.disabled, .disabled.cb-btn-outline-admin, .btn-outline-primary:disabled, .cb-btn-outline-admin:disabled {
    color: #fb6142;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .cb-btn-outline-admin:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .cb-btn-outline-admin:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle,
  .show > .dropdown-toggle.cb-btn-outline-admin {
    color: white;
    background-color: #fb6142;
    border-color: #fb6142; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .cb-btn-outline-admin:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .cb-btn-outline-admin:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus,
    .show > .dropdown-toggle.cb-btn-outline-admin:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(251, 97, 66, 0.5); }

.btn-outline-secondary {
  color: #73808e;
  background-color: transparent;
  background-image: none;
  border-color: #73808e; }
  .btn-outline-secondary:hover {
    color: white;
    background-color: #73808e;
    border-color: #73808e; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(115, 128, 142, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #73808e;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: white;
    background-color: #73808e;
    border-color: #73808e; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(115, 128, 142, 0.5); }

.btn-outline-success {
  color: #66b035;
  background-color: transparent;
  background-image: none;
  border-color: #66b035; }
  .btn-outline-success:hover {
    color: white;
    background-color: #66b035;
    border-color: #66b035; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 176, 53, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #66b035;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: white;
    background-color: #66b035;
    border-color: #66b035; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(102, 176, 53, 0.5); }

.btn-outline-info {
  color: #73808e;
  background-color: transparent;
  background-image: none;
  border-color: #73808e; }
  .btn-outline-info:hover {
    color: white;
    background-color: #73808e;
    border-color: #73808e; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(115, 128, 142, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #73808e;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: white;
    background-color: #73808e;
    border-color: #73808e; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(115, 128, 142, 0.5); }

.btn-outline-warning {
  color: #efb519;
  background-color: transparent;
  background-image: none;
  border-color: #efb519; }
  .btn-outline-warning:hover {
    color: #404040;
    background-color: #efb519;
    border-color: #efb519; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 181, 25, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #efb519;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #404040;
    background-color: #efb519;
    border-color: #efb519; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(239, 181, 25, 0.5); }

.btn-outline-danger {
  color: #b94a48;
  background-color: transparent;
  background-image: none;
  border-color: #b94a48; }
  .btn-outline-danger:hover {
    color: white;
    background-color: #b94a48;
    border-color: #b94a48; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(185, 74, 72, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #b94a48;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: white;
    background-color: #b94a48;
    border-color: #b94a48; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(185, 74, 72, 0.5); }

.btn-outline-light {
  color: #f9f9f9;
  background-color: transparent;
  background-image: none;
  border-color: #f9f9f9; }
  .btn-outline-light:hover {
    color: #404040;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f9f9f9;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #404040;
    background-color: #f9f9f9;
    border-color: #f9f9f9; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(249, 249, 249, 0.5); }

.btn-outline-dark {
  color: #404040;
  background-color: transparent;
  background-image: none;
  border-color: #404040; }
  .btn-outline-dark:hover {
    color: white;
    background-color: #404040;
    border-color: #404040; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #404040;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: white;
    background-color: #404040;
    border-color: #404040; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(64, 64, 64, 0.5); }

.btn-outline-primary-darkest {
  color: #ec2c05;
  background-color: transparent;
  background-image: none;
  border-color: #ec2c05; }
  .btn-outline-primary-darkest:hover {
    color: white;
    background-color: #ec2c05;
    border-color: #ec2c05; }
  .btn-outline-primary-darkest:focus, .btn-outline-primary-darkest.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 44, 5, 0.5); }
  .btn-outline-primary-darkest.disabled, .btn-outline-primary-darkest:disabled {
    color: #ec2c05;
    background-color: transparent; }
  .btn-outline-primary-darkest:not(:disabled):not(.disabled):active, .btn-outline-primary-darkest:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-darkest.dropdown-toggle {
    color: white;
    background-color: #ec2c05;
    border-color: #ec2c05; }
    .btn-outline-primary-darkest:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-darkest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-darkest.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(236, 44, 5, 0.5); }

.btn-outline-primary-gray-light {
  color: #faf6f5;
  background-color: transparent;
  background-image: none;
  border-color: #faf6f5; }
  .btn-outline-primary-gray-light:hover {
    color: #404040;
    background-color: #faf6f5;
    border-color: #faf6f5; }
  .btn-outline-primary-gray-light:focus, .btn-outline-primary-gray-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 246, 245, 0.5); }
  .btn-outline-primary-gray-light.disabled, .btn-outline-primary-gray-light:disabled {
    color: #faf6f5;
    background-color: transparent; }
  .btn-outline-primary-gray-light:not(:disabled):not(.disabled):active, .btn-outline-primary-gray-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-gray-light.dropdown-toggle {
    color: #404040;
    background-color: #faf6f5;
    border-color: #faf6f5; }
    .btn-outline-primary-gray-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-gray-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-gray-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(250, 246, 245, 0.5); }

.btn-outline-accent {
  color: #efb519;
  background-color: transparent;
  background-image: none;
  border-color: #efb519; }
  .btn-outline-accent:hover {
    color: #404040;
    background-color: #efb519;
    border-color: #efb519; }
  .btn-outline-accent:focus, .btn-outline-accent.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 181, 25, 0.5); }
  .btn-outline-accent.disabled, .btn-outline-accent:disabled {
    color: #efb519;
    background-color: transparent; }
  .btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active,
  .show > .btn-outline-accent.dropdown-toggle {
    color: #404040;
    background-color: #efb519;
    border-color: #efb519; }
    .btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-accent.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(239, 181, 25, 0.5); }

.btn-outline-medium {
  color: #bababa;
  background-color: transparent;
  background-image: none;
  border-color: #bababa; }
  .btn-outline-medium:hover {
    color: #404040;
    background-color: #bababa;
    border-color: #bababa; }
  .btn-outline-medium:focus, .btn-outline-medium.focus {
    box-shadow: 0 0 0 0.2rem rgba(186, 186, 186, 0.5); }
  .btn-outline-medium.disabled, .btn-outline-medium:disabled {
    color: #bababa;
    background-color: transparent; }
  .btn-outline-medium:not(:disabled):not(.disabled):active, .btn-outline-medium:not(:disabled):not(.disabled).active,
  .show > .btn-outline-medium.dropdown-toggle {
    color: #404040;
    background-color: #bababa;
    border-color: #bababa; }
    .btn-outline-medium:not(:disabled):not(.disabled):active:focus, .btn-outline-medium:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-medium.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(186, 186, 186, 0.5); }

.btn-outline-gray {
  color: #888888;
  background-color: transparent;
  background-image: none;
  border-color: #888888; }
  .btn-outline-gray:hover {
    color: white;
    background-color: #888888;
    border-color: #888888; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(136, 136, 136, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #888888;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: white;
    background-color: #888888;
    border-color: #888888; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(136, 136, 136, 0.5); }

.btn-outline-tan {
  color: #f6f6f6;
  background-color: transparent;
  background-image: none;
  border-color: #f6f6f6; }
  .btn-outline-tan:hover {
    color: #404040;
    background-color: #f6f6f6;
    border-color: #f6f6f6; }
  .btn-outline-tan:focus, .btn-outline-tan.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 246, 246, 0.5); }
  .btn-outline-tan.disabled, .btn-outline-tan:disabled {
    color: #f6f6f6;
    background-color: transparent; }
  .btn-outline-tan:not(:disabled):not(.disabled):active, .btn-outline-tan:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tan.dropdown-toggle {
    color: #404040;
    background-color: #f6f6f6;
    border-color: #f6f6f6; }
    .btn-outline-tan:not(:disabled):not(.disabled):active:focus, .btn-outline-tan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tan.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(246, 246, 246, 0.5); }

.btn-link {
  font-weight: 400;
  color: #fb6142;
  background-color: transparent; }
  .btn-link:hover {
    color: #d32704;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #888888; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .c-TitleBar__actionButton {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .c-TitleBar__actionButton {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #252525;
  text-align: left;
  list-style: none;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175); }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eee; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #252525;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #181818;
    text-decoration: none;
    background: #f9f9f9 linear-gradient(180deg, #fafafa, #f9f9f9) repeat-x; }
  .dropdown-item.active, .dropdown-item:active {
    color: white;
    text-decoration: none;
    background: #fb6142 linear-gradient(180deg, #fc795e, #fb6142) repeat-x; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #888888;
    background-color: transparent;
    background-image: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #888888;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .btn-group > .c-TitleBar__actionButton,
  .btn-group-vertical > .btn,
  .btn-group-vertical > .c-TitleBar__actionButton {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover, .btn-group > .c-TitleBar__actionButton:hover,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .c-TitleBar__actionButton:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .c-TitleBar__actionButton:focus, .btn-group > .btn:active, .btn-group > .c-TitleBar__actionButton:active, .btn-group > .btn.active, .btn-group > .active.c-TitleBar__actionButton,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .c-TitleBar__actionButton:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .c-TitleBar__actionButton:active,
    .btn-group-vertical > .btn.active,
    .btn-group-vertical > .active.c-TitleBar__actionButton {
      z-index: 1; }
  .btn-group .btn + .btn, .btn-group .c-TitleBar__actionButton + .btn, .btn-group .btn + .c-TitleBar__actionButton, .btn-group .c-TitleBar__actionButton + .c-TitleBar__actionButton,
  .btn-group .btn + .btn-group,
  .btn-group .c-TitleBar__actionButton + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .c-TitleBar__actionButton,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .c-TitleBar__actionButton + .btn,
  .btn-group-vertical .btn + .c-TitleBar__actionButton,
  .btn-group-vertical .c-TitleBar__actionButton + .c-TitleBar__actionButton,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .c-TitleBar__actionButton + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .c-TitleBar__actionButton,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child, .btn-group > .c-TitleBar__actionButton:first-child {
  margin-left: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .c-TitleBar__actionButton + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .c-TitleBar__actionButton + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn, .btn-group-vertical .c-TitleBar__actionButton,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn, .btn-group-vertical > .c-TitleBar__actionButton + .btn, .btn-group-vertical > .btn + .c-TitleBar__actionButton, .btn-group-vertical > .c-TitleBar__actionButton + .c-TitleBar__actionButton,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .c-TitleBar__actionButton + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .c-TitleBar__actionButton,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .c-TitleBar__actionButton,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .c-TitleBar__actionButton {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .c-TitleBar__actionButton input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .c-TitleBar__actionButton input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .c-TitleBar__actionButton input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .c-TitleBar__actionButton input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .c-TitleBar__actionButton,
  .input-group-append .btn,
  .input-group-append .c-TitleBar__actionButton {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn, .input-group-prepend .c-TitleBar__actionButton + .btn, .input-group-prepend .btn + .c-TitleBar__actionButton, .input-group-prepend .c-TitleBar__actionButton + .c-TitleBar__actionButton,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .c-TitleBar__actionButton + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .c-TitleBar__actionButton,
  .input-group-append .btn + .btn,
  .input-group-append .c-TitleBar__actionButton + .btn,
  .input-group-append .btn + .c-TitleBar__actionButton,
  .input-group-append .c-TitleBar__actionButton + .c-TitleBar__actionButton,
  .input-group-append .btn + .input-group-text,
  .input-group-append .c-TitleBar__actionButton + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .c-TitleBar__actionButton {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #575757;
  text-align: center;
  white-space: nowrap;
  background-color: #eee;
  border: 1px solid #d0d0d0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #888888; }

.nav-tabs {
  border-bottom: 1px solid #bababa; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #d0d0d0 #d0d0d0 transparent; }
    .nav-tabs .nav-link.disabled {
      color: #888888;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #404040;
    background-color: white;
    border-color: #bababa; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white;
  background-color: #fb6142; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0; }
  .navbar > .container, .navbar > .cb-container-fixed,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 0;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .cb-container-fixed,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container, .navbar-expand-sm > .cb-container-fixed,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .cb-container-fixed,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container, .navbar-expand-md > .cb-container-fixed,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .cb-container-fixed,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container, .navbar-expand-lg > .cb-container-fixed,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .cb-container-fixed,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container, .navbar-expand-xl > .cb-container-fixed,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container, .navbar-expand > .cb-container-fixed,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container, .navbar-expand > .cb-container-fixed,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card, .c-Box, .c-ContestBox {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .card > hr, .c-Box > hr, .c-ContestBox > hr {
    margin-right: 0;
    margin-left: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%; }

.card-img-top {
  width: 100%; }

.card-img-bottom {
  width: 100%; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card, .card-deck .c-Box, .card-deck .c-ContestBox {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card, .card-deck .c-Box, .card-deck .c-ContestBox {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card, .card-group > .c-Box, .card-group > .c-ContestBox {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card, .card-group > .c-Box, .card-group > .c-ContestBox {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card, .card-group > .c-Box + .card, .card-group > .c-ContestBox + .card, .card-group > .card + .c-Box, .card-group > .c-Box + .c-Box, .card-group > .c-ContestBox + .c-Box, .card-group > .card + .c-ContestBox, .card-group > .c-Box + .c-ContestBox, .card-group > .c-ContestBox + .c-ContestBox {
          margin-left: 0;
          border-left: 0; } }

.card-columns .card, .l-BoxLayout__columns .card, .card-columns .c-Box, .l-BoxLayout__columns .c-Box, .card-columns .c-ContestBox, .l-BoxLayout__columns .c-ContestBox {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns, .l-BoxLayout__columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card, .l-BoxLayout__columns .card, .card-columns .c-Box, .l-BoxLayout__columns .c-Box, .card-columns .c-ContestBox, .l-BoxLayout__columns .c-ContestBox {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  color: #575757;
  content: ">"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #575757; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #fb6142;
  background-color: white;
  border: 1px solid #e0e0e0; }
  .page-link:hover {
    color: #d32704;
    text-decoration: none;
    background-color: #eee;
    border-color: #e0e0e0; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(251, 97, 66, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0; }

.page-item.active .page-link {
  z-index: 1;
  color: white;
  background-color: #fb6142;
  border-color: #fb6142; }

.page-item.disabled .page-link {
  color: #888888;
  pointer-events: none;
  cursor: auto;
  background-color: white;
  border-color: #e0e0e0; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }
  .badge:empty {
    display: none; }

.btn .badge, .c-TitleBar__actionButton .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }

.badge-primary {
  color: white;
  background-color: #fb6142; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: white;
    text-decoration: none;
    background-color: #fa3710; }

.badge-secondary {
  color: white;
  background-color: #73808e; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: white;
    text-decoration: none;
    background-color: #5c6772; }

.badge-success {
  color: white;
  background-color: #66b035; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: white;
    text-decoration: none;
    background-color: #4f8929; }

.badge-info {
  color: white;
  background-color: #73808e; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: white;
    text-decoration: none;
    background-color: #5c6772; }

.badge-warning {
  color: #404040;
  background-color: #efb519; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #404040;
    text-decoration: none;
    background-color: #c7950e; }

.badge-danger {
  color: white;
  background-color: #b94a48; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: white;
    text-decoration: none;
    background-color: #953b39; }

.badge-light {
  color: #404040;
  background-color: #f9f9f9; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #404040;
    text-decoration: none;
    background-color: #e0dfdf; }

.badge-dark {
  color: white;
  background-color: #404040; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: white;
    text-decoration: none;
    background-color: #272626; }

.badge-primary-darkest {
  color: white;
  background-color: #ec2c05; }
  .badge-primary-darkest[href]:hover, .badge-primary-darkest[href]:focus {
    color: white;
    text-decoration: none;
    background-color: #ba2204; }

.badge-primary-gray-light {
  color: #404040;
  background-color: #faf6f5; }
  .badge-primary-gray-light[href]:hover, .badge-primary-gray-light[href]:focus {
    color: #404040;
    text-decoration: none;
    background-color: #ead7d3; }

.badge-accent {
  color: #404040;
  background-color: #efb519; }
  .badge-accent[href]:hover, .badge-accent[href]:focus {
    color: #404040;
    text-decoration: none;
    background-color: #c7950e; }

.badge-medium {
  color: #404040;
  background-color: #bababa; }
  .badge-medium[href]:hover, .badge-medium[href]:focus {
    color: #404040;
    text-decoration: none;
    background-color: #a1a0a0; }

.badge-gray {
  color: white;
  background-color: #888888; }
  .badge-gray[href]:hover, .badge-gray[href]:focus {
    color: white;
    text-decoration: none;
    background-color: #6f6e6e; }

.badge-tan {
  color: #404040;
  background-color: #f6f6f6; }
  .badge-tan[href]:hover, .badge-tan[href]:focus {
    color: #404040;
    text-decoration: none;
    background-color: #dddcdc; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #833222;
  background: #fedfd9 linear-gradient(180deg, #fee4df, #fedfd9) repeat-x;
  border-color: #fed3ca; }
  .alert-primary hr {
    border-top-color: #febeb1; }
  .alert-primary .alert-link {
    color: #5b2317; }

.alert-secondary {
  color: #3c434a;
  background: #e3e6e8 linear-gradient(180deg, #e7eaeb, #e3e6e8) repeat-x;
  border-color: #d8dbdf; }
  .alert-secondary hr {
    border-top-color: #caced4; }
  .alert-secondary .alert-link {
    color: #252a2e; }

.alert-success {
  color: #355c1c;
  background: #e0efd7 linear-gradient(180deg, #e5f1dd, #e0efd7) repeat-x;
  border-color: #d4e9c6; }
  .alert-success hr {
    border-top-color: #c6e2b4; }
  .alert-success .alert-link {
    color: #1e3510; }

.alert-info {
  color: #3c434a;
  background: #e3e6e8 linear-gradient(180deg, #e7eaeb, #e3e6e8) repeat-x;
  border-color: #d8dbdf; }
  .alert-info hr {
    border-top-color: #caced4; }
  .alert-info .alert-link {
    color: #252a2e; }

.alert-warning {
  color: #7c5e0d;
  background: #fcf0d1 linear-gradient(180deg, #fcf2d8, #fcf0d1) repeat-x;
  border-color: #fbeabf; }
  .alert-warning hr {
    border-top-color: #fae2a7; }
  .alert-warning .alert-link {
    color: #4e3b08; }

.alert-danger {
  color: #602625;
  background: #f1dbda linear-gradient(180deg, #f3e0e0, #f1dbda) repeat-x;
  border-color: #ebcccc; }
  .alert-danger hr {
    border-top-color: #e4baba; }
  .alert-danger .alert-link {
    color: #3b1717; }

.alert-light {
  color: #818181;
  background: #fefefe linear-gradient(180deg, #fefefe, #fefefe) repeat-x;
  border-color: #fdfdfd; }
  .alert-light hr {
    border-top-color: #f0f0f0; }
  .alert-light .alert-link {
    color: #686767; }

.alert-dark {
  color: #212121;
  background: #d9d9d9 linear-gradient(180deg, #dfdfdf, #d9d9d9) repeat-x;
  border-color: #cacaca; }
  .alert-dark hr {
    border-top-color: #bdbdbd; }
  .alert-dark .alert-link {
    color: #080808; }

.alert-primary-darkest {
  color: #7a1703;
  background: #fbd5cd linear-gradient(180deg, #fcdbd5, #fbd5cd) repeat-x;
  border-color: #fac4b9; }
  .alert-primary-darkest hr {
    border-top-color: #f8b0a1; }
  .alert-primary-darkest .alert-link {
    color: #480e02; }

.alert-primary-gray-light {
  color: #828080;
  background: #fefdfd linear-gradient(180deg, #fefdfd, #fefdfd) repeat-x;
  border-color: #fefcfc; }
  .alert-primary-gray-light hr {
    border-top-color: #f8e9e9; }
  .alert-primary-gray-light .alert-link {
    color: #686767; }

.alert-accent {
  color: #7c5e0d;
  background: #fcf0d1 linear-gradient(180deg, #fcf2d8, #fcf0d1) repeat-x;
  border-color: #fbeabf; }
  .alert-accent hr {
    border-top-color: #fae2a7; }
  .alert-accent .alert-link {
    color: #4e3b08; }

.alert-medium {
  color: #616161;
  background: #f1f1f1 linear-gradient(180deg, #f3f3f3, #f1f1f1) repeat-x;
  border-color: #ececec; }
  .alert-medium hr {
    border-top-color: #dfdfdf; }
  .alert-medium .alert-link {
    color: #484747; }

.alert-gray {
  color: #474747;
  background: #e7e7e7 linear-gradient(180deg, #ebebeb, #e7e7e7) repeat-x;
  border-color: #dedede; }
  .alert-gray hr {
    border-top-color: #d1d1d1; }
  .alert-gray .alert-link {
    color: #2e2d2d; }

.alert-tan {
  color: gray;
  background: #fdfdfd linear-gradient(180deg, #fdfdfd, #fdfdfd) repeat-x;
  border-color: #fcfcfc; }
  .alert-tan hr {
    border-top-color: #efefef; }
  .alert-tan .alert-link {
    color: #676666; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #575757;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #575757;
    text-decoration: none;
    background-color: #f9f9f9; }
  .list-group-item-action:active {
    color: #252525;
    background-color: #eee; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:last-child {
    margin-bottom: 0; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #888888;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: white;
    background-color: #fb6142;
    border-color: #fb6142; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #833222;
  background-color: #fed3ca; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #833222;
    background-color: #febeb1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #833222;
    border-color: #833222; }

.list-group-item-secondary {
  color: #3c434a;
  background-color: #d8dbdf; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #3c434a;
    background-color: #caced4; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3c434a;
    border-color: #3c434a; }

.list-group-item-success {
  color: #355c1c;
  background-color: #d4e9c6; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #355c1c;
    background-color: #c6e2b4; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #355c1c;
    border-color: #355c1c; }

.list-group-item-info {
  color: #3c434a;
  background-color: #d8dbdf; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #3c434a;
    background-color: #caced4; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #3c434a;
    border-color: #3c434a; }

.list-group-item-warning {
  color: #7c5e0d;
  background-color: #fbeabf; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7c5e0d;
    background-color: #fae2a7; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7c5e0d;
    border-color: #7c5e0d; }

.list-group-item-danger {
  color: #602625;
  background-color: #ebcccc; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #602625;
    background-color: #e4baba; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #602625;
    border-color: #602625; }

.list-group-item-light {
  color: #818181;
  background-color: #fdfdfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818181;
    background-color: #f0f0f0; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818181;
    border-color: #818181; }

.list-group-item-dark {
  color: #212121;
  background-color: #cacaca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #212121;
    background-color: #bdbdbd; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #212121;
    border-color: #212121; }

.list-group-item-primary-darkest {
  color: #7a1703;
  background-color: #fac4b9; }
  .list-group-item-primary-darkest.list-group-item-action:hover, .list-group-item-primary-darkest.list-group-item-action:focus {
    color: #7a1703;
    background-color: #f8b0a1; }
  .list-group-item-primary-darkest.list-group-item-action.active {
    color: #fff;
    background-color: #7a1703;
    border-color: #7a1703; }

.list-group-item-primary-gray-light {
  color: #828080;
  background-color: #fefcfc; }
  .list-group-item-primary-gray-light.list-group-item-action:hover, .list-group-item-primary-gray-light.list-group-item-action:focus {
    color: #828080;
    background-color: #f8e9e9; }
  .list-group-item-primary-gray-light.list-group-item-action.active {
    color: #fff;
    background-color: #828080;
    border-color: #828080; }

.list-group-item-accent {
  color: #7c5e0d;
  background-color: #fbeabf; }
  .list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
    color: #7c5e0d;
    background-color: #fae2a7; }
  .list-group-item-accent.list-group-item-action.active {
    color: #fff;
    background-color: #7c5e0d;
    border-color: #7c5e0d; }

.list-group-item-medium {
  color: #616161;
  background-color: #ececec; }
  .list-group-item-medium.list-group-item-action:hover, .list-group-item-medium.list-group-item-action:focus {
    color: #616161;
    background-color: #dfdfdf; }
  .list-group-item-medium.list-group-item-action.active {
    color: #fff;
    background-color: #616161;
    border-color: #616161; }

.list-group-item-gray {
  color: #474747;
  background-color: #dedede; }
  .list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
    color: #474747;
    background-color: #d1d1d1; }
  .list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: #474747;
    border-color: #474747; }

.list-group-item-tan {
  color: gray;
  background-color: #fcfcfc; }
  .list-group-item-tan.list-group-item-action:hover, .list-group-item-tan.list-group-item-action:focus {
    color: gray;
    background-color: #efefef; }
  .list-group-item-tan.list-group-item-action.active {
    color: #fff;
    background-color: gray;
    border-color: gray; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0.125rem 0.375rem rgba(0, 0, 0, 0.6);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #d0d0d0; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #d0d0d0; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-content {
    box-shadow: 0 0 0.25rem 0.625rem rgba(0, 0, 0, 0.6); }
  .modal-sm {
    max-width: 400px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 850px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #fb6142 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #fa3710 !important; }

.bg-secondary {
  background-color: #73808e !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #5c6772 !important; }

.bg-success {
  background-color: #66b035 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #4f8929 !important; }

.bg-info {
  background-color: #73808e !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #5c6772 !important; }

.bg-warning {
  background-color: #efb519 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c7950e !important; }

.bg-danger {
  background-color: #b94a48 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #953b39 !important; }

.bg-light {
  background-color: #f9f9f9 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e0dfdf !important; }

.bg-dark {
  background-color: #404040 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #272626 !important; }

.bg-primary-darkest {
  background-color: #ec2c05 !important; }

a.bg-primary-darkest:hover, a.bg-primary-darkest:focus,
button.bg-primary-darkest:hover,
button.bg-primary-darkest:focus {
  background-color: #ba2204 !important; }

.bg-primary-gray-light {
  background-color: #faf6f5 !important; }

a.bg-primary-gray-light:hover, a.bg-primary-gray-light:focus,
button.bg-primary-gray-light:hover,
button.bg-primary-gray-light:focus {
  background-color: #ead7d3 !important; }

.bg-accent {
  background-color: #efb519 !important; }

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #c7950e !important; }

.bg-medium {
  background-color: #bababa !important; }

a.bg-medium:hover, a.bg-medium:focus,
button.bg-medium:hover,
button.bg-medium:focus {
  background-color: #a1a0a0 !important; }

.bg-gray {
  background-color: #888888 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #6f6e6e !important; }

.bg-tan {
  background-color: #f6f6f6 !important; }

a.bg-tan:hover, a.bg-tan:focus,
button.bg-tan:hover,
button.bg-tan:focus {
  background-color: #dddcdc !important; }

.bg-gradient-primary {
  background: #fb6142 linear-gradient(180deg, #fc795e, #fb6142) repeat-x !important; }

.bg-gradient-secondary {
  background: #73808e linear-gradient(180deg, #88939f, #73808e) repeat-x !important; }

.bg-gradient-success {
  background: #66b035 linear-gradient(180deg, #7dbc53, #66b035) repeat-x !important; }

.bg-gradient-info {
  background: #73808e linear-gradient(180deg, #88939f, #73808e) repeat-x !important; }

.bg-gradient-warning {
  background: #efb519 linear-gradient(180deg, #f1c03c, #efb519) repeat-x !important; }

.bg-gradient-danger {
  background: #b94a48 linear-gradient(180deg, #c46563, #b94a48) repeat-x !important; }

.bg-gradient-light {
  background: #f9f9f9 linear-gradient(180deg, #fafafa, #f9f9f9) repeat-x !important; }

.bg-gradient-dark {
  background: #404040 linear-gradient(180deg, #5d5d5d, #404040) repeat-x !important; }

.bg-gradient-primary-darkest {
  background: #ec2c05 linear-gradient(180deg, #ee4b2a, #ec2c05) repeat-x !important; }

.bg-gradient-primary-gray-light {
  background: #faf6f5 linear-gradient(180deg, #fbf7f7, #faf6f5) repeat-x !important; }

.bg-gradient-accent {
  background: #efb519 linear-gradient(180deg, #f1c03c, #efb519) repeat-x !important; }

.bg-gradient-medium {
  background: #bababa linear-gradient(180deg, #c4c4c4, #bababa) repeat-x !important; }

.bg-gradient-gray {
  background: #888888 linear-gradient(180deg, #9a9a9a, #888888) repeat-x !important; }

.bg-gradient-tan {
  background: #f6f6f6 linear-gradient(180deg, #f7f7f7, #f6f6f6) repeat-x !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #d0d0d0 !important; }

.border-top {
  border-top: 1px solid #d0d0d0 !important; }

.border-right {
  border-right: 1px solid #d0d0d0 !important; }

.border-bottom {
  border-bottom: 1px solid #d0d0d0 !important; }

.border-left {
  border-left: 1px solid #d0d0d0 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #fb6142 !important; }

.border-secondary {
  border-color: #73808e !important; }

.border-success {
  border-color: #66b035 !important; }

.border-info {
  border-color: #73808e !important; }

.border-warning {
  border-color: #efb519 !important; }

.border-danger {
  border-color: #b94a48 !important; }

.border-light {
  border-color: #f9f9f9 !important; }

.border-dark {
  border-color: #404040 !important; }

.border-primary-darkest {
  border-color: #ec2c05 !important; }

.border-primary-gray-light {
  border-color: #faf6f5 !important; }

.border-accent {
  border-color: #efb519 !important; }

.border-medium {
  border-color: #bababa !important; }

.border-gray {
  border-color: #888888 !important; }

.border-tan {
  border-color: #f6f6f6 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.3125rem !important; }

.rounded-top {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important; }

.rounded-right {
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important; }

.rounded-left {
  border-top-left-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after, .c-Headline::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .cb-d-flex-between, .cb-d-flex-between-center, .cb-d-flex-center-center {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714286%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center, .cb-d-flex-center-center {
  justify-content: center !important; }

.justify-content-between, .cb-d-flex-between, .cb-d-flex-between-center {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .cb-d-flex-between-center, .cb-d-flex-center-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column, .p-Proposal__formBox__label {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2, .cb-btn-spacer .btn-block + .btn-block,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .cb-btn-spacer .btn:not(.btn-block) + .btn:not(.btn-block), .cb-btn-spacer .c-TitleBar__actionButton:not(.btn-block) + .btn:not(.btn-block), .cb-btn-spacer .btn:not(.btn-block) + .c-TitleBar__actionButton:not(.btn-block), .cb-btn-spacer .c-TitleBar__actionButton:not(.btn-block) + .c-TitleBar__actionButton:not(.btn-block),
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 400 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #fb6142 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #fa3710 !important; }

.text-secondary {
  color: #73808e !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #5c6772 !important; }

.text-success {
  color: #66b035 !important; }

a.text-success:hover, a.text-success:focus {
  color: #4f8929 !important; }

.text-info {
  color: #73808e !important; }

a.text-info:hover, a.text-info:focus {
  color: #5c6772 !important; }

.text-warning {
  color: #efb519 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #c7950e !important; }

.text-danger {
  color: #b94a48 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #953b39 !important; }

.text-light {
  color: #f9f9f9 !important; }

a.text-light:hover, a.text-light:focus {
  color: #e0dfdf !important; }

.text-dark {
  color: #404040 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #272626 !important; }

.text-primary-darkest {
  color: #ec2c05 !important; }

a.text-primary-darkest:hover, a.text-primary-darkest:focus {
  color: #ba2204 !important; }

.text-primary-gray-light {
  color: #faf6f5 !important; }

a.text-primary-gray-light:hover, a.text-primary-gray-light:focus {
  color: #ead7d3 !important; }

.text-accent {
  color: #efb519 !important; }

a.text-accent:hover, a.text-accent:focus {
  color: #c7950e !important; }

.text-medium {
  color: #bababa !important; }

a.text-medium:hover, a.text-medium:focus {
  color: #a1a0a0 !important; }

.text-gray {
  color: #888888 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #6f6e6e !important; }

.text-tan {
  color: #f6f6f6 !important; }

a.text-tan:hover, a.text-tan:focus {
  color: #dddcdc !important; }

.text-muted {
  color: #888888 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn):not(.c-TitleBar__actionButton) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container, .cb-container-fixed {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

ol.breadcrumb li,
ul.pagination li {
  margin-left: 0; }

.nav-item .badge {
  margin-left: 1rem; }

.cb-btn-admin {
  color: white;
  background: #fb6142 linear-gradient(180deg, #fc795e, #fb6142) repeat-x;
  border-color: #efb519;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cb-btn-admin:hover {
    color: white;
    background: #fa421d linear-gradient(180deg, #fb5e3f, #fa421d) repeat-x;
    border-color: #c7950e; }
  .cb-btn-admin:focus, .cb-btn-admin.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(239, 181, 25, 0.5); }
  .cb-btn-admin.disabled, .cb-btn-admin:disabled {
    color: white;
    background-color: #fb6142;
    border-color: #efb519; }
  .cb-btn-admin:not(:disabled):not(.disabled):active, .cb-btn-admin:not(:disabled):not(.disabled).active,
  .show > .cb-btn-admin.dropdown-toggle {
    color: white;
    background-color: #fa3710;
    background-image: none;
    border-color: #bb8c0d; }
    .cb-btn-admin:not(:disabled):not(.disabled):active:focus, .cb-btn-admin:not(:disabled):not(.disabled).active:focus,
    .show > .cb-btn-admin.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(239, 181, 25, 0.5); }

.cb-btn-outline-admin, .cb-btn-outline-admin:hover {
  border-color: #efb519; }

.cb-btn-group-active-btn {
  pointer-events: none;
  box-shadow: 0 0 6px 1px #252525; }
  .cb-btn-group-active-btn.btn-outline-primary, .cb-btn-group-active-btn.cb-btn-outline-admin {
    background: #fb6142 linear-gradient(180deg, #fc795e, #fb6142) repeat-x;
    color: white; }

.cb-btn-group-justified {
  display: flex;
  width: 100%; }
  .cb-btn-group-justified .btn, .cb-btn-group-justified .c-TitleBar__actionButton,
  .cb-btn-group-justified .btn-group {
    flex: 1; }
    .cb-btn-group-justified .btn .btn, .cb-btn-group-justified .c-TitleBar__actionButton .btn, .cb-btn-group-justified .btn .c-TitleBar__actionButton, .cb-btn-group-justified .c-TitleBar__actionButton .c-TitleBar__actionButton,
    .cb-btn-group-justified .btn-group .btn,
    .cb-btn-group-justified .btn-group .c-TitleBar__actionButton {
      width: 100%; }
    .cb-btn-group-justified .btn .dropdown-menu, .cb-btn-group-justified .c-TitleBar__actionButton .dropdown-menu,
    .cb-btn-group-justified .btn-group .dropdown-menu {
      left: auto; }

.cb-btn-xs {
  padding: 0.1875rem 0.4rem;
  line-height: 1.25;
  font-weight: 400; }

.cb-btn-xl {
  padding: 1rem 1.5rem;
  font-weight: 700; }

.cb-btn-image-text {
  vertical-align: middle; }

.cb-btn-image {
  margin-right: .1875rem;
  margin-top: -0.1875rem;
  margin-bottom: -0.1875rem;
  margin-left: -0.375rem; }
  .btn-sm .cb-btn-image, .btn-group-sm > .btn .cb-btn-image, .btn-group-sm > .c-TitleBar__actionButton .cb-btn-image {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
    margin-left: -0.25rem; }
  .btn-lg .cb-btn-image, .btn-group-lg > .btn .cb-btn-image, .btn-group-lg > .c-TitleBar__actionButton .cb-btn-image {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
    margin-left: -0.5rem; }

@media (min-width: 768px) {
  .cb-modal-md {
    width: 450px; }
  .cb-modal-lg {
    width: 600px; } }

@media (min-width: 992px) {
  .cb-modal-xl {
    max-width: 900px; } }

@media (min-width: 768px) {
  .cb-form-control-inline.form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .cb-form-control-inline.cb-form-control-xs {
    width: 50px; }
  .cb-form-control-inline.cb-form-control-sm {
    width: 200px; }
  .cb-form-control-inline.cb-form-control-md {
    width: 300px; }
  .cb-form-control-inline.cb-form-control-lg {
    width: 400px; } }

.form-control.cb-form-has-error {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .form-control.cb-form-has-error:focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.form-row ~ .form-row {
  margin-top: 1rem; }

.cb-form-col-label {
  font-weight: 700;
  display: flex;
  align-items: center; }

.checkbox label {
  float: none !important;
  width: initial !important; }

.checkbox input[type=checkbox] {
  float: none !important;
  height: initial !important;
  width: initial !important; }

.cb-list-group-striped .list-group-item:nth-of-type(odd) {
  background: #f6f6f6; }

.nav li,
.navbar li {
  margin-left: 0; }

.navbar-nav .nav-link {
  font-weight: 400; }
  @media (min-width: 992px) {
    .navbar-nav .nav-link {
      line-height: 67px;
      padding-top: 0;
      padding-bottom: 0; } }

@media (min-width: 992px) {
  .navbar-nav .nav-item.cb-nav-item-multi-line {
    height: 67px; }
    .navbar-nav .nav-item.cb-nav-item-multi-line .nav-link {
      line-height: normal; } }

@media (min-width: 992px) {
  .navbar-nav .cb-nav-item-icon .nav-link {
    height: 67px;
    line-height: 1;
    padding-top: 10px;
    text-align: center; } }

.navbar-nav .cb-nav-item-icon .nav-icon {
  display: none;
  height: 30px;
  width: 30px; }
  @media (min-width: 992px) {
    .navbar-nav .cb-nav-item-icon .nav-icon {
      display: initial; }
      .navbar-nav .cb-nav-item-icon .nav-icon + .cb-nav-text {
        display: block;
        margin-top: 5px; } }

.navbar-nav .cb-nav-item-image .nav-link > img {
  height: 25px;
  width: 25px;
  border: 1px solid #888888; }

.navbar-nav .cb-nav-item-image .nav-link .badge {
  padding: 2px 5px;
  font-weight: 400; }
  @media (min-width: 992px) {
    .navbar-nav .cb-nav-item-image .nav-link .badge {
      position: absolute;
      top: 14px;
      right: 10px; } }

@media (min-width: 992px) {
  .navbar-nav .cb-nav-item-separator-left {
    border-left: 1px solid #bababa;
    margin-left: 0.5rem;
    padding-left: 0.5rem; } }

@media (min-width: 992px) {
  .cb-nav-pages {
    margin-left: 1rem; }
    .cb-nav-pages .nav-item {
      border-left: 1px solid #bababa; }
      .cb-nav-pages .nav-item.dropdown.show {
        border-left-color: white; } }

.navbar-light .navbar-nav .nav-link {
  color: #fb6142; }
  .navbar-light .navbar-nav .nav-link:hover {
    color: black; }

.navbar-light .navbar-nav .nav-item:not(.show) .c-Icon__search {
  fill: #73808e; }

@media (min-width: 992px) {
  .navbar-light .navbar-nav .active {
    background: white;
    margin-bottom: -1px;
    box-shadow: inset 0 -8px 0 #fb6142; } }

#current-language {
  background: none; }

@media (min-width: 992px) {
  .navbar-nav.cb-nav-pages .nav-link {
    padding-left: 1rem;
    padding-right: 1rem; } }

.cb-nav-text {
  font-weight: 700; }

@media (min-width: 992px) {
  .navbar-light .navbar-nav .cb-nav-item-gray .nav-link {
    color: #575757; }
    .navbar-light .navbar-nav .cb-nav-item-gray .nav-link:hover {
      color: #fb6142; } }

@media (min-width: 992px) {
  .cb-nav-item-sm {
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: 700; } }

.cb-nav-item-sm .dropdown-menu {
  font-size: initial;
  font-weight: initial;
  text-transform: initial; }

.cb-navbar-background {
  background: white;
  border-bottom: 1px solid #bababa;
  box-shadow: 0 5px 2px -2px rgba(136, 136, 136, 0.7); }

.cb-navbar-background-white {
  background: white; }

.cb-navbar-button {
  display: flex;
  align-items: center;
  border-left: 0 !important; }
  @media (max-width: 991.98px) {
    .cb-navbar-button > .btn, .cb-navbar-button > .c-TitleBar__actionButton {
      padding: .5rem 0;
      background: none;
      color: var(--primary);
      border: 0;
      box-shadow: none;
      font-size: 15px; } }

.nav-tabs .nav-item .nav-link {
  font-weight: bold; }
  .nav-tabs .nav-item .nav-link.active {
    box-shadow: inset 0 -3px #73808e; }

.dropdown-toggle {
  cursor: pointer; }
  .dropdown-toggle.cb-dropdown-toggle-no-caret:after {
    content: none; }

.dropdown-menu .dropdown-item {
  background: none;
  margin-left: 0; }

.cb-dropdown-box {
  padding: 15px 15px; }

.cb-dropdown-inverse .dropdown-header {
  color: #bababa; }

.cb-dropdown-inverse .dropdown-menu {
  background-color: rgba(51, 51, 51, 0.9);
  color: white;
  border: none; }
  .cb-dropdown-inverse .dropdown-menu a, .cb-dropdown-inverse .dropdown-menu label {
    color: white;
    border-bottom: 1px solid transparent; }
    .cb-dropdown-inverse .dropdown-menu a:hover, .cb-dropdown-inverse .dropdown-menu a:focus, .cb-dropdown-inverse .dropdown-menu label:hover, .cb-dropdown-inverse .dropdown-menu label:focus {
      color: white;
      background: none;
      border-bottom: 1px dashed #fff;
      cursor: pointer; }
  .cb-dropdown-inverse .dropdown-menu input[type=checkbox], .cb-dropdown-inverse .dropdown-menu input[type=radio] {
    margin-right: 5px; }
  .cb-dropdown-inverse .dropdown-menu .dropdown-item a {
    font-weight: 700;
    display: inline-block; }
  .cb-dropdown-inverse .dropdown-menu .dropdown-item:hover {
    background: none; }
  .cb-dropdown-inverse .dropdown-menu .dropdown-divider {
    background-color: white; }

.navbar .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.navbar .nav-item.dropdown.show .nav-link, .navbar .nav-item.dropdown.show .nav-link:hover {
  color: white;
  background-color: rgba(51, 51, 51, 0.9);
  border-top-right-radius: 0.3125rem;
  border-top-left-radius: 0.3125rem; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fb6142; }

.table-hover .table-primary:hover {
  background-color: #fa4c29; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fa4c29; }

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #bababa; }

.table-hover .table-gray:hover {
  background-color: #adadad; }
  .table-hover .table-gray:hover > td,
  .table-hover .table-gray:hover > th {
    background-color: #adadad; }

.table.cb-table-striped-inverse tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.0235294); }

.table.cb-table-striped-columns tbody tr td:nth-of-type(even) {
  background: #f6f6f6; }

.table thead th img {
  height: 24px;
  width: auto;
  max-width: initial; }

.table .primary th {
  color: white; }

.table th.primary {
  color: white; }

hr.cb-hr-dashed {
  border-style: dashed; }

hr.cb-hr-dotted {
  border-style: dotted; }

hr.cb-hr-lg {
  border-width: 3px; }

hr.cb-hr-xl {
  border-width: 6px; }

hr.cb-hr-primary {
  border-color: #fb6142; }

hr.cb-hr-secondary {
  border-color: #73808e; }

hr.cb-hr-success {
  border-color: #66b035; }

hr.cb-hr-info {
  border-color: #73808e; }

hr.cb-hr-warning {
  border-color: #efb519; }

hr.cb-hr-danger {
  border-color: #b94a48; }

hr.cb-hr-light {
  border-color: #f9f9f9; }

hr.cb-hr-dark {
  border-color: #404040; }

hr.cb-hr-primary-darkest {
  border-color: #ec2c05; }

hr.cb-hr-primary-gray-light {
  border-color: #faf6f5; }

hr.cb-hr-accent {
  border-color: #efb519; }

hr.cb-hr-medium {
  border-color: #bababa; }

hr.cb-hr-gray {
  border-color: #888888; }

hr.cb-hr-tan {
  border-color: #f6f6f6; }

@media (max-width: 767.98px) {
  h1 {
    font-size: 1.5rem; }
  h2 {
    font-size: 1.34375rem; }
  h3 {
    font-size: 1.125rem; }
  h4 {
    font-size: 1.03125rem; }
  h5 {
    font-size: 1rem; }
  h6 {
    font-size: 1rem; } }

.cb-text-lg {
  font-size: 120%; }

.cb-text-bold {
  font-weight: 700; }

.cb-w-auto {
  width: auto !important; }

.cb-no-wrap {
  white-space: nowrap !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.cb-flex-basis-100 {
  flex-basis: 100%; }

.cb-flex-basis-auto {
  flex-basis: auto; }

.cb-collapse-show.collapse {
  display: block; }

@media (min-width: 576px) {
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .cb-flex-sm-basis-100 {
    flex-basis: 100%; }
  .cb-flex-sm-basis-auto {
    flex-basis: auto; }
  .cb-collapse-sm-show.collapse {
    display: block; } }

@media (min-width: 768px) {
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .cb-flex-md-basis-100 {
    flex-basis: 100%; }
  .cb-flex-md-basis-auto {
    flex-basis: auto; }
  .cb-collapse-md-show.collapse {
    display: block; } }

@media (min-width: 992px) {
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .cb-flex-lg-basis-100 {
    flex-basis: 100%; }
  .cb-flex-lg-basis-auto {
    flex-basis: auto; }
  .cb-collapse-lg-show.collapse {
    display: block; } }

@media (min-width: 1200px) {
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .cb-flex-xl-basis-100 {
    flex-basis: 100%; }
  .cb-flex-xl-basis-auto {
    flex-basis: auto; }
  .cb-collapse-xl-show.collapse {
    display: block; } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, b, i, sub, sup, tt, var, caption,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, input,
table, tbody, tfoot, thead, tr, th, td {
  padding: 0; }

table {
  width: 100%; }

td {
  vertical-align: top; }

td, th {
  text-align: left;
  line-height: normal; }

ol, ul {
  margin-left: 0; }
  .cke_editable ol, .cke_editable ul {
    margin-left: 15px; }

ol li {
  margin-left: 20px; }

ul li {
  margin-left: 11px; }

img {
  max-width: 100%; }
  img.media-object {
    max-width: initial; }

body {
  font-size: 15px;
  font-weight: 400;
  color: initial; }

h1 {
  font-weight: 400;
  color: #404040;
  line-height: 1.1;
  text-transform: none; }

h2 {
  font-weight: 700;
  text-transform: none; }

h3 {
  font-weight: 700; }

h4 {
  font-weight: 400; }

h5 {
  font-weight: 400; }

h6 {
  font-weight: 400; }

:root {
  --primary: #fb6142;
  --secondary: #73808e;
  --accent: #efb519;
  --tan: #f6f6f6;
  --primary--light: #fec5ba;
  --secondary--light: #b7bdc5;
  --primary--dark: #fa401a;
  --secondary--dark: #555f6a;
  --primary--darkest: #ec2c05;
  --secondary--darkest: #2e343a;
  --primary__gray: #d2aba3;
  --primary__gray--light: #faf6f5;
  --primary__gray--dark: #c0887d;
  --gray--light-100: #f9f9f9;
  --gray--light-200: #eee;
  --gray--light-300: #e0e0e0;
  --gray--light-400: #d0d0d0;
  --gray--medium-500: #bababa;
  --gray--dark-600: #888888;
  --gray--dark-700: #575757;
  --gray--dark-800: #404040;
  --gray--dark-900: #252525;
  --success: #66b035;
  --danger: #b94a48;
  --info: #73808e;
  --warning: #efb519; }

#content {
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative; }

#c-Footer__menu__right {
  float: right;
  min-width: 500px;
  height: 44px;
  position: relative; }

#c-Footer__menu__right p {
  margin: 0 0 0 0; }

.l-BoxLayout__columns {
  column-count: 1; }
  @media (min-width: 768px) {
    .l-BoxLayout__columns {
      column-count: 2; } }

.l-BoxLayout__stacked .c-Box ~ .c-Box {
  margin-top: 1rem; }

.l-BoxLayout--striped .c-Box:nth-of-type(even) {
  background: #bababa;
  color: #404040; }

.l-Content {
  display: flex;
  flex-direction: column; }

.l-Content__main, .l-Content__sidebar {
  margin: 0.5rem 0; }

.l-Content__main {
  flex: 1 1 auto; }

.l-Content__sidebar {
  order: -1;
  flex-grow: 1; }

.l-Content__sidebar--collapseBottom {
  order: 99; }

@media (min-width: 768px) {
  .l-Content:not(.l-Content--break-early) {
    flex-direction: row; }
    .l-Content:not(.l-Content--break-early) .l-Content__sidebar--small {
      flex-basis: 250px; }
    .l-Content:not(.l-Content--break-early) .l-Content__sidebar--smallest {
      flex-basis: 200px; }
    .l-Content:not(.l-Content--break-early) .l-Content__sidebar--large {
      flex-basis: 350px; }
    .l-Content:not(.l-Content--break-early) .l-Content__sidebar--right {
      margin-left: 30px;
      margin-right: 0;
      order: 99; }
    .l-Content:not(.l-Content--break-early) .l-Content__sidebar {
      margin-right: 30px;
      flex: 0 0 300px; } }

@media (min-width: 992px) {
  .l-Content.l-Content--break-early {
    flex-direction: row; }
    .l-Content.l-Content--break-early .l-Content__sidebar--small {
      flex-basis: 250px; }
    .l-Content.l-Content--break-early .l-Content__sidebar--smallest {
      flex-basis: 200px; }
    .l-Content.l-Content--break-early .l-Content__sidebar--large {
      flex-basis: 350px; }
    .l-Content.l-Content--break-early .l-Content__sidebar--right {
      margin-left: 30px;
      margin-right: 0;
      order: 99; }
    .l-Content.l-Content--break-early .l-Content__sidebar {
      margin-right: 30px;
      flex: 0 0 300px; } }

.c-Badge__container {
  text-align: center;
  width: 490px;
  float: right; }

.c-Badge--big, .c-Badge--small {
  position: relative;
  float: left; }
  .badge-1.c-Badge--big, .badge-1.c-Badge--small {
    background-image: url(/images/ribbons/contest-badge-big-1.png); }
  .badge-2.c-Badge--big, .badge-2.c-Badge--small {
    background-image: url(/images/ribbons/contest-badge-big-2.png); }
  .badge-3.c-Badge--big, .badge-3.c-Badge--small {
    background-image: url(/images/ribbons/contest-badge-big-3.png); }

.c-Badge--big {
  width: 183px;
  height: 161px;
  background-size: 183px 161px;
  margin: 0 30px; }

.c-Badge--small {
  width: 68px;
  height: 60px;
  background-size: 68px 60px;
  margin: 0 10px; }

.c-Badge__title--small, .c-Badge__title--big {
  font-weight: 700;
  color: #404040;
  position: absolute; }

.c-Badge__title--small {
  font-size: 6px;
  top: 15px;
  left: 24px;
  width: 20px; }

.c-Badge__title--big {
  font-size: 19px;
  top: 40px;
  left: 52px;
  width: 80px; }

.c-Badge__year--small, .c-Badge__year--big {
  color: white;
  font-weight: 700;
  position: absolute;
  transform: rotate(323deg); }

.c-Badge__year--small {
  font-size: 9px;
  left: 44px;
  top: 36px; }

.c-Badge__year--big {
  font-size: 11px;
  left: 124px;
  top: 106px; }

.c-Badge__text--small, .c-Badge__text--big {
  font-weight: 700;
  color: white;
  position: absolute;
  transform: rotate(396deg); }

.c-Badge__text--small {
  font-size: 9px;
  left: 10px;
  top: 36px; }

.c-Badge__text--big {
  width: 49px;
  font-size: 8px;
  left: 20px;
  top: 101px; }

.c-Box {
  border: none; }
  .c-Box.c-Box--colorPrimary {
    background: #fb6142;
    color: white; }
  .c-Box.c-Box--colorTan {
    background: #f6f6f6;
    color: #404040; }
  .c-Box.c-Box--colorPrimaryGrayLight {
    background: #faf6f5;
    color: #404040; }
  .c-Box.c-Box--colorDark {
    background-color: #f6f6f6;
    color: #404040; }
  .c-Box.c-Box--hasShadow {
    box-shadow: none; }
  .c-Box.c-Box--noBorder {
    border: none; }
  .c-Box.c-Box--straightCorners {
    border-radius: 0; }
  .c-Box hr {
    margin: 0; }

.c-Box__body {
  padding: 1rem; }

.c-Box__bodyFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem; }
  .c-Box__bodyFlex.c-Box__bodyFlex--columns {
    flex-direction: column;
    align-items: flex-start; }

.c-Box__bodyRow {
  padding: 1rem; }

.c-Caret::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.c-Caret:empty::after {
  margin-left: 0; }

.c-Caret.c-Caret--up::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.c-Caret.c-Caret--up:empty::after {
  margin-left: 0; }

/* Carousel Logos */
.c-CarouselWrapper {
  text-align: center;
  margin-bottom: 18px; }
  .c-CarouselWrapper h2 {
    overflow: hidden;
    text-align: center;
    margin-bottom: 10px !important;
    /* Lines left and right of heading */ }
    .c-CarouselWrapper h2:before, .c-CarouselWrapper h2:after {
      background-color: #B3B3B3;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%; }
    .c-CarouselWrapper h2:before {
      right: 0.5em;
      margin-left: -50%; }
    .c-CarouselWrapper h2:after {
      left: 0.5em;
      margin-right: -50%; }

.c-CarouselBox {
  display: inline-block; }
  .c-CarouselBox > :last-child {
    padding-right: 0 !important; }

.c-CarouselLogo {
  height: 120px;
  line-height: 120px;
  display: inline-block;
  padding-right: 50px; }
  .c-CarouselLogo img {
    max-height: 80%;
    max-width: 170px;
    height: auto;
    width: auto; }

.c-Comment {
  width: 693px;
  padding: 28px;
  position: relative;
  line-height: 22px;
  color: #404040; }
  .c-Comment p {
    margin: 0 0 10px 0; }
  .c-Comment img {
    max-width: 650px; }

.c-Comment__box {
  height: 178px;
  width: 788px;
  border: 2px solid #bababa;
  margin-bottom: 25px; }
  .c-Comment__box.discussion {
    width: 700px; }

.c-Comment__actions {
  margin-top: 15px;
  text-align: right; }

.c-Comment__list {
  font-size: 15px; }
  .c-Comment__list tr.odd td {
    background-color: #f6f6f6; }
  .c-Comment__list td {
    border-bottom: 3px solid white; }

.c-Comment.spam, .comments tr.odd td.c-Comment.spam {
  background-color: #f2dede; }

.c-Comment__commentor {
  background-image: url(/images/pattern-6x6.png);
  width: 212px;
  padding: 16px 20px 26px 16px;
  line-height: 19px;
  color: black; }
  .c-Comment__commentor a {
    font-weight: 700; }
  .c-Comment__commentor img {
    margin-bottom: 14px; }
  .c-Comment__commentor span {
    font-weight: 700; }

.c-Box .c-Comment {
  width: 100%;
  padding: 0; }

.c-ContentBox {
  border: 1px solid #fb6142;
  padding: 15px;
  margin-bottom: 10px; }

.c-ContentBox h1 {
  padding-bottom: 15px; }

.c-ContentBox .dropzone {
  height: 30px;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #0066b3;
  color: #0066b3; }

.c-ContentBox .dropzone.showAllowDropState.allowDropState {
  background-color: #CCC;
  border: 1px solid blue; }

.c-ContentBox .dropzone.showAllowDropState {
  background-color: #e7f1f5;
  border: 1px dashed blue; }

.c-ContentBox .c-Box.dragState {
  background-color: #CCC;
  border: 1px solid blue; }

.c-ContentBox .addpropInputContainer input {
  width: 910px; }

.c-ContentBox .cke_skin_colab {
  width: 910px; }

.c-ContentBox .addpropInputContainer textarea {
  width: 910px;
  height: auto;
  resize: both; }

.c-ContentBox .c-Comment__add {
  height: 300px; }

.c-ContentBox .actionItems {
  text-align: center; }

.c-ContentBox .deleteIcon {
  position: relative;
  right: -7px;
  top: -7px;
  float: right;
  background-image: url("/images/icon_question-x.png");
  width: 22px;
  height: 22px; }

.c-ContentBox .contestPhases .deleteIcon {
  right: 0;
  top: 0; }

.c-ContentBox .addSection {
  margin: 20px; }

.c-ContentBox .contest-outline-left {
  float: none;
  width: auto;
  min-height: inherit;
  padding-bottom: 0; }

.c-ContentBox .c-ContentBox__Table input {
  padding-left: 10px; }

.c-ContentBox .c-ContentBox__Table tr {
  white-space: nowrap;
  line-height: 30px;
  height: 30px;
  background-color: #f2f1e9;
  display: table-row;
  margin-bottom: 0;
  border-bottom: 5px solid white;
  transition: 0.3s ease-out 0s;
  padding: inherit;
  overflow: hidden;
  text-overflow: ellipsis; }

.c-ContentBox .c-ContentBox__Table tr.draggable {
  cursor: move; }

.c-ContentBox .c-ContentBox__Table .blue tr {
  background-color: #e7f1f5; }

.c-ContentBox .c-ContentBox__Table tr.blue.drag {
  transform: scale(0.9); }

.c-ContentBox .c-ContentBox__Table tr.drag {
  transform: scale(0.9);
  border-bottom: 5px solid white; }

.c-ContentBox .c-ContentBox__Table tr.allowDrop {
  transition: 0.3s ease-out 0s;
  border-top: 34px solid white; }

.c-ContentBox .c-ContentBox__Table td {
  border-collapse: collapse;
  vertical-align: middle;
  text-align: center;
  line-height: inherit; }

.c-ContentBox .c-ContentBox__Table th {
  vertical-align: middle;
  text-align: center;
  line-height: 30px; }

.c-ContestBox {
  position: relative;
  border: none;
  background: #f6f6f6; }
  .c-ContestBox:hover {
    box-shadow: 0 0 15px #bababa; }
  .c-ContestBox, .card-deck.card-deck .c-ContestBox {
    margin-bottom: 1.5rem; }
    @media (min-width: 576px) {
      .c-ContestBox, .card-deck.card-deck .c-ContestBox {
        flex-direction: row; } }

.c-ContestBox__image {
  align-self: center;
  flex: 0 0 auto;
  margin: .5rem; }
  .c-ContestBox__image > img {
    border-radius: 0.3125rem; }

.c-ContestBox__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: .5rem; }

.c-ContestBox__text {
  font-size: 1rem;
  line-height: 1.3333;
  color: #575757; }

.c-ContestBox__title {
  margin-bottom: .5rem; }
  .c-ContestBox__title a {
    color: #fb6142; }
  .c-ContestBox__title a:hover {
    color: #fa3710;
    text-decoration: none; }

.c-ContestBox__metaCount {
  margin-top: .5rem;
  color: #888888; }

.c-ContestBox__metaFlag {
  display: inline-block;
  padding: 0 8px;
  margin-top: 10px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  text-align: center;
  text-transform: none;
  box-shadow: none; }

.c-ContestBox__metaFlag--color {
  background: #73808e; }

.c-ContestBox__metaFlag--grey {
  background: #888888; }

.c-ContestCard {
  width: 200px;
  border: 1px solid #bababa;
  border-radius: 8px;
  background: white;
  margin-bottom: 24px;
  float: left;
  position: relative;
  height: 400px;
  margin-right: 35px; }
  .c-ContestCard.backgroundLight {
    background: white; }
  .c-ContestCard:hover {
    box-shadow: 0 0 10px #bababa; }

.c-ContestCard__image {
  float: left;
  width: 200px; }
  .c-ContestCard__image img {
    height: 200px;
    width: 200px;
    border-top: 1px solid #bababa;
    border-bottom: 1px solid #bababa; }

.c-ContestCard__text {
  float: left;
  width: 184px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  overflow: hidden;
  color: #575757;
  height: 104px; }

.c-ContestCard__text__title {
  margin-bottom: 8px;
  font-size: 18px;
  color: #fb6142;
  line-height: 22px;
  overflow: hidden; }
  .c-ContestCard__text__title:hover {
    color: #d32704;
    text-decoration: none; }

.c-ContestCard__meta__count {
  color: #888888;
  float: left;
  height: 26px;
  width: 184px;
  padding: 8px;
  font-size: 14px; }
  .c-ContestCard__meta__count .prop {
    display: inline;
    padding-right: 10px;
    text-transform: lowercase; }
  .c-ContestCard__meta__count .comm {
    display: inline; }
  .c-ContestCard__meta__count strong {
    color: #fb6142; }

.c-ContestCard__meta__flags {
  height: 20px;
  display: inline-block;
  margin: 8px;
  float: left; }
  .c-ContestCard__meta__flags div {
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 3px;
    line-height: 14px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase; }

.c-ContestCard__meta__flag--grey {
  background: #faf6f5;
  color: #888888; }

.c-ContestCard__meta__flag--color {
  background: #73808e;
  color: white; }

.c-ControlsOverlay {
  background-color: rgba(246, 246, 246, 0.9);
  font-size: 20px;
  line-height: 29px;
  border-top: 2px solid #fb6142;
  padding: 25px 15px;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99; }

.c-CollectionCard__background__container {
  width: 200px;
  background-color: white;
  margin-bottom: 24px;
  float: left;
  position: relative;
  height: 400px;
  margin-right: 35px;
  border: 1px solid #bababa;
  border-radius: 8px; }
  .c-CollectionCard__background__container:hover {
    box-shadow: 0 0 10px #bababa; }

.c-CollectionCard__separator {
  border-bottom: 2px solid #f6f6f6;
  width: 184px;
  margin-left: 8px;
  float: left; }

.c-CollectionCard__circle {
  border-radius: 50%;
  height: 40px;
  background-color: #fb6142;
  width: 40px;
  float: left;
  margin-left: 80px;
  color: white;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  position: relative;
  top: -22px; }

.c-CollectionCard__plceholder__top {
  height: 80px; }

.c-CollectionCard__contests__tag {
  color: #888888;
  font-size: 12px;
  text-align: center;
  position: relative;
  width: 200px;
  float: left;
  top: -15px; }

.c-CollectionCard {
  width: 200px;
  border: 1px solid #bababa;
  border-radius: 8px;
  background: white;
  margin-bottom: 24px;
  float: left;
  position: relative;
  height: 400px;
  margin-right: 20px; }
  .c-CollectionCard.backgroundLight {
    background: white; }
  .c-CollectionCard:hover {
    box-shadow: 0 0 10px #bababa; }

.c-CollectionCard__image {
  float: left;
  width: 200px; }
  .c-CollectionCard__image img {
    height: 200px;
    width: 200px;
    border-top: 1px solid #bababa;
    border-bottom: 1px solid #bababa; }

.c-CollectionCard__text {
  float: left;
  width: 184px;
  font-size: 14px;
  padding: 8px;
  line-height: 19px;
  color: #575757;
  height: 170px;
  overflow: hidden; }

.c-CollectionCard__text__title {
  color: #fb6142;
  padding: 8px;
  text-align: center;
  float: left;
  width: 184px;
  height: 60px;
  overflow: hidden; }
  .c-CollectionCard__text__title h3 {
    font-size: 18px;
    line-height: 22px; }
  .c-CollectionCard__text__title:hover {
    color: #d32704;
    text-decoration: none; }

.c-CollectionCard__text__parent__title {
  font-size: 13px;
  color: black;
  width: 200px;
  margin-top: 30px;
  text-align: center;
  float: left; }

.c-Icon {
  fill: currentColor; }
  .btn .c-Icon, .c-TitleBar__actionButton .c-Icon {
    vertical-align: middle;
    height: 2em;
    width: 2em; }

.c-ListView {
  background-color: #faf6f5;
  overflow: hidden; }

.c-ListItem {
  border-radius: 3px;
  color: #bababa;
  height: 60px;
  float: left;
  width: 800px;
  margin-left: 110px;
  padding-bottom: 15px;
  background-color: white;
  margin-bottom: 15px;
  border: 1px solid #bababa; }
  .c-ListItem:hover {
    box-shadow: 0 0 3px 3px #bababa; }

.c-ListItem--background-container {
  border-radius: 3px;
  color: #bababa;
  height: 60px;
  float: left;
  width: 800px;
  padding-bottom: 15px;
  background-color: white;
  margin-bottom: 15px;
  border: 1px solid #bababa; }
  .c-ListItem--background-container:hover {
    box-shadow: 0 0 3px 3px #bababa; }

.c-ListItem__description {
  width: 474px;
  float: left;
  padding: 8px;
  font-size: 14px;
  line-height: 19px; }
  .c-ListItem__description h3 {
    color: #fb6142;
    font-size: 18px;
    line-height: 22px; }
    .c-ListItem__description h3:hover {
      color: black; }
  .c-ListItem__description div {
    overflow: hidden;
    height: 38px; }

.c-ListItem__meta {
  width: 214px;
  float: right;
  padding: 8px;
  height: 60px; }

.c-ListItem__proposals {
  float: right;
  padding: 8px;
  padding-right: 0; }
  .c-ListItem__proposals strong {
    color: #fb6142; }

.c-ListItem__comments {
  float: right;
  padding: 8px; }
  .c-ListItem__comments strong {
    color: #fb6142; }

.c-ListItem__phase {
  margin-bottom: 20px; }

.c-ListItem__meta__flag {
  text-align: right;
  height: 20px; }
  .c-ListItem__meta__flag span {
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 3px;
    line-height: 20px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase; }

.c-ListItem__meta__flag--grey {
  color: #888888;
  background: #f6f6f6; }

.c-ListItem__meta__flag--color {
  color: white;
  background: #73808e; }

.c-ListItem__contests {
  border-right: 1px dashed #bababa;
  height: 59px;
  width: 55px;
  padding: 8px;
  float: left;
  color: #888888; }

.c-ListItem__circle {
  color: white;
  text-align: center;
  font-size: 15px;
  line-height: 40px;
  border-radius: 50%;
  height: 40px;
  background-color: #fb6142;
  width: 40px;
  float: left;
  margin-left: 8px; }

.c-Count {
  display: inline-block;
  vertical-align: middle;
  text-transform: none;
  font-size: 1.0625rem; }
  @media (min-width: 576px) {
    .c-Count {
      font-size: 1.125rem; } }

.c-Count__number {
  display: inline-block;
  margin-right: 0.25rem;
  font-weight: 700;
  font-size: 1.125rem;
  color: #fb6142; }
  @media (min-width: 576px) {
    .c-Count__number {
      font-size: 1.25rem; } }
  .c-Count--accent .c-Count__number {
    color: #efb519; }
  .c-Count--neutral .c-Count__number {
    color: initial; }

#c-Footer {
  background: #f6f6f6; }

#c-Footer__menu {
  padding: 8px 0;
  background: transparent;
  box-shadow: none; }
  #c-Footer__menu .nav-link {
    color: black; }
    #c-Footer__menu .nav-link.active {
      color: #fb6142; }
    #c-Footer__menu .nav-link:hover {
      color: #fb6142;
      cursor: pointer; }

#footleft {
  float: left;
  width: 420px;
  padding-top: 21px; }

#footright {
  float: right;
  width: 510px;
  padding-top: 34px; }
  #footright #cc {
    padding-top: 6px; }
  #footright #txt {
    float: left;
    width: 400px;
    margin-right: 16px;
    font-size: 13px;
    color: #575757;
    line-height: 20px; }
    #footright #txt a {
      padding-bottom: 2px;
      border-bottom: 1px solid #bababa;
      font-size: 12px;
      font-weight: 700;
      color: black; }
      #footright #txt a:hover {
        color: #fb6142;
        text-decoration: none; }

.c-Footer__text {
  font-size: .8125rem;
  color: #575757; }
  .c-Footer__text a {
    padding-bottom: 2px;
    border-bottom: 1px solid #bababa;
    font-size: .75rem;
    font-weight: 700;
    color: black; }
    .c-Footer__text a:hover {
      color: #fb6142;
      text-decoration: none; }

.c-Footer__icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  vertical-align: middle; }

.c-Form__selectbox {
  padding: 0 44px 0 9px;
  width: 100%;
  height: 35px;
  display: block;
  background: url(/images/butt_arrowbdr.png) no-repeat right;
  font-size: 15px;
  font-style: italic;
  color: #666;
  border: none;
  cursor: pointer; }
  .c-Form__selectbox span {
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    color: #efb519; }

.c-Form__selectbox__new {
  overflow: hidden; }

input.c-Form__selectbox, input[type='text'].c-Form__selectbox {
  width: 100%;
  height: 35px;
  display: block;
  background: url(/images/butt_arrowbdr.png) no-repeat right;
  font-size: 17px;
  font-style: italic;
  color: #666;
  border: none;
  cursor: pointer;
  padding: 0 35px 0 9px; }
  input.c-Form__selectbox span, input[type='text'].c-Form__selectbox span {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    color: #efb519; }

.c-Header__displayName {
  max-width: 180px;
  text-align: right; }

.c-Headline {
  display: flex;
  margin-bottom: 1rem; }
  .c-Headline > img {
    margin-right: .5rem; }
  .c-Headline h1 {
    margin-right: 30px; }

.c-Message__number {
  font-size: 34px; }

.c-Message {
  margin: 45px 0 20px 0;
  font-size: 15px;
  font-style: italic;
  color: #666; }

.c-Message__input {
  width: 390px;
  height: 27px;
  padding: 2px 0 0 8px;
  background: #F2F1E9 url(/images/sendMessage_textarea.gif) no-repeat;
  border: none;
  font-size: 13px;
  font-weight: 400; }

.c-Message__titleWrap {
  float: left;
  width: 100%; }

.c-Message__subject {
  display: inline-block; }

.c-PreviousMessage {
  color: #ec2c05;
  border-left: 1px solid;
  margin-left: 10px;
  margin-top: 2%;
  padding-left: 5px; }

.c-PreviousMessage__header {
  color: #2e343a;
  opacity: 0.8;
  font-size: smaller; }

.c-PreviousMessage .c-PreviousMessage .c-PreviousMessage .c-PreviousMessage .c-PreviousMessage {
  color: #ec2c05;
  border-left: none;
  margin-left: 0px;
  margin-top: 1%;
  padding-top: 2%;
  padding-left: 0px;
  position: relative; }
  .c-PreviousMessage .c-PreviousMessage .c-PreviousMessage .c-PreviousMessage .c-PreviousMessage:after {
    content: "";
    background: #d2aba3;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 10%;
    height: 1px;
    width: 70%; }

.c-Scrollable {
  overflow-y: scroll; }

.c-Scrollable--visibleScrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5; }

.c-Scrollable--visibleScrollbar::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }

.c-Scrollable--visibleScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #404040; }

.c-SideMenu ul {
  margin-bottom: 0; }

.c-SideMenu li {
  list-style: none;
  padding: 0;
  margin: 0; }
  .c-SideMenu li > a {
    display: block;
    padding-left: 0.5rem;
    line-height: 2em;
    font-size: 18px;
    font-weight: 700;
    color: #fb6142; }
  .c-SideMenu li > img + a {
    display: inline-block;
    vertical-align: middle; }
  .c-SideMenu li > a:hover,
  .c-SideMenu li.c-SideMenu__link--active > a {
    color: #404040;
    text-decoration: none; }
  .c-SideMenu li ul {
    padding: 0.5rem 0; }
    @media (min-width: 768px) {
      .c-SideMenu li ul {
        padding: 1rem 0; } }
    .c-SideMenu li ul > li {
      margin-left: 0.5rem; }
      .c-SideMenu li ul > li > a {
        font-size: 0.9em; }

.c-SideMenu--compact li > a {
  font-weight: 400;
  line-height: 1.25em; }

.c-SideMenu--styled li > a {
  border-bottom: 1px solid #bababa; }

.c-SideMenu--styled li > a:hover,
.c-SideMenu--styled li.c-SideMenu__link--active > a {
  padding-left: calc(0.5rem - 5px);
  background: #f6f6f6;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #73808e;
  color: #404040; }

.c-SideMenu--styled li ul {
  border-bottom: 1px solid #bababa; }
  .c-SideMenu--styled li ul li > a {
    border-bottom: none; }

.c-SocialButton {
  display: inline-block;
  margin: 0.125rem;
  cursor: pointer; }
  .c-SocialButton > img {
    height: 30px;
    width: 30px;
    margin: 0;
    padding: 0; }
  .c-SocialButton.c-SocialButton--lg > img {
    height: 35px;
    width: 35px; }
  .c-SocialButton.c-SocialButton--sm > img {
    height: 25px;
    width: 25px; }
  .c-SocialButton.c-SocialButton--xs > img {
    height: 20px;
    width: 20px; }

.c-SocialButton--hoverTranslate {
  transition: all .2s ease-in-out;
  animation-fill-mode: both; }
  .c-SocialButton--hoverTranslate:hover {
    transform: translateY(-4px); }

.c-SocialButton__text {
  margin-left: 5px; }
  .c-SocialButton__text:hover {
    border-bottom: 1px dashed; }

.c-Ribbon {
  position: fixed;
  width: 110px;
  height: 28px;
  background: #fb6142;
  top: 11px;
  left: -31px;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  line-height: 28px;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: 1075; }

.c-TabBar {
  margin-top: 8px;
  padding-top: 35px;
  margin-bottom: 0;
  background: white;
  border-top: 1px solid #888888;
  border-bottom: none; }

.c-TabBar__tab, .c-TabBar__tab--first, .c-TabBar__tab--last {
  border-top: none;
  border-left: none;
  float: left;
  list-style: none;
  cursor: pointer;
  margin-right: 0; }
  .c-TabBar__tab a, .c-TabBar__tab--first a, .c-TabBar__tab--last a {
    display: inline-block;
    padding-top: 17px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 16px;
    font-size: 14px;
    background: rgba(255, 255, 255, 0);
    color: #fb6142;
    text-transform: uppercase;
    font-weight: 700;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; }
  .c-TabBar__tab a:hover, .c-TabBar__tab--first a:hover, .c-TabBar__tab--last a:hover {
    background: rgba(230, 229, 229, 0);
    text-decoration: none; }
  .cb-text-sm.c-TabBar__tab a, .cb-text-sm.c-TabBar__tab--first a, .cb-text-sm.c-TabBar__tab--last a, .small.c-TabBar__tab a, .small.c-TabBar__tab--first a, .small.c-TabBar__tab--last a {
    padding-top: 5.66666667px;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 5.33333333px;
    font-size: 12px;
    font-weight: 400; }
  .active.c-TabBar__tab, .active.c-TabBar__tab--first, .active.c-TabBar__tab--last {
    border-top: 1px solid #888888;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; }
  .active.c-TabBar__tab a, .active.c-TabBar__tab--first a, .active.c-TabBar__tab--last a {
    color: #404040;
    background: white;
    box-shadow: inset 0 -4px 0 #73808e; }
  .highlight.c-TabBar__tab a, .highlight.c-TabBar__tab--first a, .highlight.c-TabBar__tab--last a {
    background: #73808e; }

.c-TabBar__tab {
  margin-left: 0;
  border-right: 1px solid #888888; }

.c-TabBar__tab--first {
  margin-left: 30px;
  border-left: 1px solid #888888;
  border-right: 1px solid #888888; }

.c-TabBar__tab--last {
  margin-left: 0;
  border-right: 1px solid #888888; }

.c-TabBar__tab__count {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  background: #bababa;
  color: #404040;
  padding: 0 5px;
  margin-left: 5px;
  border-radius: 8px; }

.c-TableGrid__header, .c-TableGrid__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid #888888; }
  .c-TableGrid__header a, .c-TableGrid__row a {
    font-weight: 700; }
  .c-TableGrid__row--continued.c-TableGrid__header, .c-TableGrid__row--continued.c-TableGrid__row {
    border-bottom: none; }
    .c-TableGrid__row--continued.c-TableGrid__header .c-TableGrid__cell, .c-TableGrid__row--continued.c-TableGrid__row .c-TableGrid__cell {
      padding-bottom: 0; }

.c-TableGrid__header {
  background-color: #f6f6f6;
  border-bottom-width: 2px;
  font-weight: 700; }
  .c-TableGrid__header .c-TableGrid__cell {
    white-space: nowrap; }

.c-TableGrid__row.c-TableGrid__row--active {
  background-color: rgba(0, 0, 0, 0.0235294); }

.c-TableGrid__cell {
  padding: .5rem; }
  .c-TableGrid__cell .c-Icon {
    display: none;
    margin-right: 10px; }
  .c-TableGrid__cell .highlight {
    background-color: white;
    border-radius: 3px;
    padding: 6px 4px;
    white-space: nowrap; }

.c-TextRule {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #404040; }
  .c-TextRule:after, .c-TextRule:before {
    content: '';
    flex: 1;
    height: 1px;
    background: #404040; }
  .c-TextRule:after {
    margin-left: 0.5rem; }
  .c-TextRule:before {
    margin-right: 0.5rem; }

.c-TextRule--inverse {
  color: white; }
  .c-TextRule--inverse:after, .c-TextRule--inverse:before {
    background: white; }

.c-TitleBar__container {
  height: 43px; }

.c-TitleBar {
  background: #fb6142;
  border-bottom: none;
  color: white;
  text-transform: none; }
  .c-TitleBar a {
    color: white; }
    .c-TitleBar a:hover {
      color: #e6e5e5; }
  .c-TitleBar.contestSummary {
    background: #fb6142; }
  .c-TitleBar .close {
    position: absolute;
    top: 8px;
    right: 16px; }

.c-TitleBar--light.c-TitleBar {
  background: #f6f6f6;
  color: initial; }

.c-TitleBar.c-TitleBar--warning {
  background: #faf6f5;
  color: #404040;
  border-bottom: 6px solid #efb519; }
  .c-TitleBar.c-TitleBar--warning .c-TitleBar__actionButton {
    color: #404040;
    background: #efb519; }
    .c-TitleBar.c-TitleBar--warning .c-TitleBar__actionButton:hover {
      color: white;
      background: #c7950e; }

.c-TitleBar.c-TitleBar--fixed {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1035; }

.c-TitleBar__content {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.c-TitleBar__element_group {
  text-wrap: none; }

.c-TitleBar__element, .c-TitleBar__element--withSeparator {
  height: 37px;
  line-height: 37px;
  display: inline-block;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 300; }
  .c-TitleBar__element.disabled, .disabled.c-TitleBar__element--withSeparator {
    color: #bababa;
    text-transform: none; }
  .c-TitleBar__element.active, .active.c-TitleBar__element--withSeparator {
    font-weight: 700;
    text-transform: none; }

.c-TitleBar__element--withSeparator {
  margin-right: 15px; }
  .c-TitleBar__element--withSeparator:after, .c-TitleBar__element--withSeparator:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .c-TitleBar__element--withSeparator:after {
    border-width: 12px;
    border-left-color: #fb6142;
    margin-top: -12px; }
  .c-TitleBar__element--withSeparator:before {
    border-width: 16px;
    border-left-color: white;
    margin-top: -16px; }

.c-TitleBar__actionButton {
  flex: 0 0 auto;
  height: 37px;
  line-height: 37px;
  padding: 0 10px;
  font-size: 0.875rem;
  border-radius: 0;
  color: white;
  background: #fb6142; }
  .c-TitleBar__actionButton:hover {
    color: white;
    background: #73808e; }
  .c-TitleBar__actionButton img {
    height: 25px;
    margin-right: 5px; }

.c-Tooltip {
  display: none; }

.c-Upload {
  text-align: center;
  float: left;
  position: relative; }
  .c-Upload img {
    display: block;
    height: 150px;
    border-radius: 0.3125rem; }
  .c-Upload .c-Upload__button {
    display: block;
    margin-top: 5px; }
  .c-Upload .c-Upload__wrapper {
    width: 150px;
    position: relative; }
  .c-Upload .c-Upload__wrapper--compact {
    display: inline; }
  .c-Upload .c-Upload__icon__clear {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 25px;
    width: 25px;
    cursor: pointer; }
  .c-Upload.c-Upload--compact {
    position: static; }
    .c-Upload.c-Upload--compact img {
      display: inline-block;
      height: 50px;
      border: 1px dashed black;
      margin-right: 20px; }
    .c-Upload.c-Upload--compact .c-Upload__button {
      display: inline-block;
      vertical-align: middle; }
    .c-Upload.c-Upload--compact .c-Upload__button__clear {
      margin-left: 10px; }

.c-UserContent a {
  word-break: break-all; }

.m-Shadebar {
  box-shadow: 0 5px 2px -2px rgba(136, 136, 136, 0.7);
  z-index: 1;
  position: relative; }

.p-ContestProposals__header {
  background-color: #f6f6f6;
  padding-top: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #bababa;
  color: #404040;
  position: relative; }

.p-ContestProposals__header__content {
  overflow: auto; }

.p-ContestProposals__header__title {
  background: none;
  min-height: 24px;
  padding: 20px 42px 20px 20px;
  font-size: 22px;
  font-weight: 400;
  vertical-align: middle; }
  .p-ContestProposals__header__title p {
    float: left;
    margin-bottom: 0; }

.p-ContestProposals__header__description {
  padding: 5px 20px; }
  .p-ContestProposals__header__description p {
    padding-right: 22px;
    line-height: 24px;
    margin: 0; }

.p-ContestProposals__header__sponsorLogo {
  max-width: 320px;
  float: right;
  margin-left: 1rem; }
  .p-ContestProposals__header__sponsorLogo > img {
    max-width: 100%;
    min-height: 30px;
    max-height: 100px; }

.p-ContestProposals__header__team {
  display: inline-block;
  padding: 10px 16px;
  position: relative; }

.p-ContestProposals__header__team__roleName {
  display: block;
  padding-top: 15px;
  width: 115px; }

.p-ContestProposals__header__team__member {
  display: inline-block;
  white-space: nowrap;
  padding-right: 10px;
  max-width: 176px;
  min-width: 176px;
  margin-top: 4px;
  margin-right: 12px; }
  .p-ContestProposals__header__team__member img {
    margin-right: 5px; }

.p-ContestProposals__header__buttons {
  padding-right: 20px;
  text-align: center; }
  @media (min-width: 992px) {
    .p-ContestProposals__header__buttons {
      text-align: right; } }

.p-ContestProposals__header__ontology {
  padding: 10px 16px; }

.p-ContestProposals__header__ontology__term {
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
  margin-right: 15px;
  width: 220px; }

.p-ContestProposals__header__ontology__term__title--what, .p-ContestProposals__header__ontology__term__title--where, .p-ContestProposals__header__ontology__term__title--who, .p-ContestProposals__header__ontology__term__title--how {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  height: 24px;
  padding-top: 1px;
  padding-right: 10px;
  background: none;
  width: auto; }

.p-ContestProposals__header__ontology__term__link {
  display: inline-block; }

.p-ContestProposals__header__shareButtons {
  padding-left: 1rem;
  margin-top: 0.5rem;
  text-align: center; }
  @media (min-width: 992px) {
    .p-ContestProposals__header__shareButtons {
      position: absolute;
      right: -32px;
      top: 20px;
      width: 35px;
      padding: 0; } }

.p-ContestProposals__list__element--proposalname, .p-ContestProposals__list__element--thumbs, .p-ContestProposals__list__element--votes, .p-ContestProposals__list__element--comments, .p-ContestProposals__list__element--modifiedDate, .p-ContestProposals__list__element--contributor, .p-ContestProposals__list__element--judgeFellow {
  float: left;
  height: 37px; }

.p-ContestProposals__list__element--proposalname {
  width: 605px;
  padding-left: 9px;
  border-right: 1px solid; }
  .p-ContestProposals__list__element--proposalname.completed {
    width: 765px; }
  .p-ContestProposals__list__element--proposalname.judgeActions {
    width: 449px; }

.p-ContestProposals__list__element--thumbs {
  width: 67px;
  position: relative;
  text-align: center;
  border-right: 1px solid; }
  .p-ContestProposals__list__element--thumbs a {
    background: url(/images/icon-proposal-vote.png) no-repeat 0 7px;
    width: 20px;
    height: 37px;
    display: inline-block; }

.p-ContestProposals__list__element--votes {
  width: 67px;
  text-align: center;
  border-right: 1px solid; }
  .p-ContestProposals__list__element--votes a {
    background: url(/images/icon-proposal-vote.png) no-repeat 0 7px;
    width: 20px;
    height: 37px;
    display: inline-block; }

.p-ContestProposals__list__element--comments {
  width: 65px;
  position: relative;
  text-align: center;
  border-right: 1px solid; }
  .p-ContestProposals__list__element--comments a {
    background: url(/images/icon-list-comment.png) no-repeat 0 10px;
    width: 24px;
    height: 37px;
    display: inline-block; }

.p-ContestProposals__list__element--modifiedDate {
  width: 85px;
  text-align: center;
  border-right: 1px solid; }

.p-ContestProposals__list__element--contributor {
  width: 146px;
  text-align: center; }

.p-ContestProposals__list__element--judgeFellow {
  width: 158px;
  position: relative;
  text-align: center;
  border-left: 1px solid; }
  .p-ContestProposals__list__element--judgeFellow a {
    font-size: 0.9em; }

.p-Members__list__element--user, .p-Members__list__element--points, .p-Members__list__element--activity, .p-Members__list__element--category, .p-Members__list__element--seniority {
  float: left;
  height: 37px; }
  .p-Members__list__element--user a, .p-Members__list__element--points a, .p-Members__list__element--activity a, .p-Members__list__element--category a, .p-Members__list__element--seniority a {
    font-weight: 700;
    display: inline-block; }

.p-Members__list__element--user {
  width: 262px; }

.p-Members__list__element--points {
  width: 80px;
  padding-left: 11px; }

.p-Members__list__element--activity {
  width: 63px; }

.p-Members__list__element--category {
  width: 73px; }

.p-Members__list__element--seniority {
  width: 101px; }

.popupreg_message {
  margin-top: 35px; }

.is-required {
  font-style: italic;
  color: #575757;
  margin-bottom: 20px; }

.popupreg_head {
  float: left;
  width: 50%; }

.popupreg_head h1 {
  color: black;
  font-size: 36px; }

.popupreg_txt.req {
  background: url(/images/reg-star.png) no-repeat 225px 4px; }

.popupreg_txt {
  float: left;
  text-align: right;
  font-weight: 700;
  width: 220px;
  height: 27px;
  padding: 6px 20px 0 0;
  color: #404040; }

.popupreg_form {
  background: white;
  width: 100%;
  padding-bottom: 25px;
  position: relative;
  top: -5px;
  left: -5px; }

.popupreg_terms {
  margin-top: 30px;
  color: #444; }

.inputLimitContainer {
  margin-top: 5px;
  font-size: 12px;
  text-align: right; }

.inputLimitContainer.overflow {
  color: #b00;
  font-weight: 700; }

.required-field:after {
  content: '*';
  color: #fb6142; }

.p-LoginRegister__signup__infofield {
  vertical-align: middle;
  color: gray;
  padding-left: 78px; }

div.req {
  background: url(../images/reg-star.png) no-repeat;
  width: 20px;
  height: 20px;
  float: left; }

table.reg th {
  padding-top: 25px;
  width: 180px; }

table.reg td {
  padding-top: 15px; }

th.second {
  padding-left: 80px;
  width: 60px; }

.shortBioContent {
  width: 100%; }

.limit_charactersMax {
  margin-right: 5px; }

/** Search page **/
.result {
  margin: 10px 0 5px 0;
  border-bottom: 1px solid #C5C5C5; }

.result.blu {
  background: #F3F8FA; }

.result_title {
  float: left;
  display: inline;
  padding: 6px 25px 0 0;
  margin: 0; }

.result_locale {
  float: right;
  background: #73808e;
  color: white;
  text-align: center;
  padding: 4px 10px 4px 10px;
  margin: 4px 10px 0;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px; }

.clear {
  clear: both;
  height: 0; }

.collectionCard-outline-left {
  width: 50%;
  float: left;
  padding-right: 60px; }
  .collectionCard-outline-left .collectionCard-category {
    padding: 10px; }
  .collectionCard-outline-left .expand {
    width: 100%; }
  .collectionCard-outline-left .collectionCardListItem {
    list-style: none; }
  .collectionCard-outline-left .level-1 ul {
    display: none; }
  .collectionCard-outline-left .level-1 li.anything a.open {
    background-color: #ec2c05; }
  .collectionCard-outline-left .level-1 li a {
    background: url(/images/contest-icon-plus.png) no-repeat 18px center; }
  .collectionCard-outline-left .level-1 li.active > a {
    color: white;
    background-color: #ec2c05; }
  .collectionCard-outline-left .level-1 li a.open {
    background-image: url(/images/contest-icon-minus-orange.png); }
  .collectionCard-outline-left .level-1 li.active a.open {
    background-image: url(/images/contest-icon-minus.png); }
  .collectionCard-outline-left .level-1 li.active a.open.active .count {
    color: white; }
  .collectionCard-outline-left .level-1 li.no-child a, .collectionCard-outline-left .level1 li.no-child a.open {
    background-image: none; }
  .collectionCard-outline-left .level-1 li.anything a {
    background: white url(/images/pattern-6x6-lite.png) repeat top left; }
  .collectionCard-outline-left ul {
    margin-bottom: 0; }
  .collectionCard-outline-left ul li {
    background: none;
    padding: 0;
    font-size: 12px; }
  .collectionCard-outline-left ul.level-1 li {
    padding-left: 0; }
  .collectionCard-outline-left ul.level-2 li {
    padding-left: 32px;
    font-size: 12px; }
  .collectionCard-outline-left ul.level-3 li {
    padding-left: 32px; }
  .collectionCard-outline-left ul.level-4 li {
    padding-left: 32px; }
  .collectionCard-outline-left ul.level-1 li a {
    padding: 10px 20px 10px 46px;
    font-size: 14px; }
  .collectionCard-outline-left ul.level-2 li a {
    padding: 10px 20px 10px 40px;
    font-size: 12px; }
  .collectionCard-outline-left ul.level-3 li a {
    padding: 10px 20px 10px 35px; }
  .collectionCard-outline-left li a {
    border-bottom: 1px solid #ccc;
    display: inline-block;
    width: 100%;
    color: black; }

.ontologyPicker {
  margin: auto;
  padding: 50px;
  display: block;
  position: absolute;
  top: 30px;
  width: 50%;
  z-index: 100;
  background-color: #f6f6f6;
  border-radius: 10px;
  box-shadow: 0 1px 40px #f6f6f6; }
  .ontologyPicker .whatArea {
    width: 90%; }
  .ontologyPicker .whereArea {
    width: 90%; }
  .ontologyPicker .ontologyTermListItem {
    list-style: none; }
  .ontologyPicker .level-1 ul {
    display: none; }
  .ontologyPicker .level-1 li.anything a.open {
    background-color: #ec2c05; }
  .ontologyPicker .level-1 li a {
    background: url(/images/contest-icon-plus.png) no-repeat 18px center; }
  .ontologyPicker .level-1 li.active > a {
    color: white;
    background-color: #ec2c05; }
  .ontologyPicker .level-1 li a.open {
    background-image: url(/images/contest-icon-minus-orange.png); }
  .ontologyPicker .level-1 li.active a.open {
    background-image: url(/images/contest-icon-minus.png); }
  .ontologyPicker .level-1 li.active a.open.active .count {
    color: white; }
  .ontologyPicker .level-1 li.no-child a, .ontologyPicker .level1 li.no-child a.open {
    background-image: none; }
  .ontologyPicker .level-1 li.anything a {
    background: white url(/images/pattern-6x6-lite.png) repeat top left; }
  .ontologyPicker ul {
    margin-bottom: 0; }
  .ontologyPicker ul li {
    background: none;
    padding: 0;
    font-size: 12px; }
  .ontologyPicker ul.level-1 li {
    padding-left: 0; }
  .ontologyPicker ul.level-2 li {
    padding-left: 32px;
    font-size: 12px; }
  .ontologyPicker ul.level-3 li {
    padding-left: 32px; }
  .ontologyPicker ul.level-4 li {
    padding-left: 32px; }
  .ontologyPicker ul.level-1 li a {
    padding: 10px 20px 10px 46px;
    font-size: 14px; }
  .ontologyPicker ul.level-2 li a {
    padding: 10px 20px 10px 40px;
    font-size: 12px; }
  .ontologyPicker ul.level-3 li a {
    padding: 10px 20px 10px 35px; }
  .ontologyPicker li a {
    border-bottom: 1px solid #ccc;
    display: inline-block;
    width: 100%;
    color: black; }

.floatLeft {
  float: left; }

.floatRight {
  float: right; }

.clearLeft {
  clear: left; }

.fileUploadFrame input {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
  z-index: 100000; }

.rotate {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  /* also accepts left, right, top, bottom coordinates; not required, but a good idea for styling */
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  /* Should be unset in IE9+ I think. */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); }

#massMessage input {
  width: 100%;
  font-size: medium;
  line-height: 30px; }

#massMessage textarea {
  width: 100%;
  font-size: medium;
  min-height: 60px; }

#selectedMassAction {
  font-size: large;
  line-height: 27px; }

#contestsFilterSelect {
  font-size: large;
  line-height: 27px; }

span.ontology-term-label {
  margin-left: 15px;
  font-style: italic; }

.boxChanged:after {
  content: '*';
  color: #30a3fb; }

#cvsContests input {
  width: 210px; }

#cvsContests span.token {
  font-size: 11px;
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  margin-left: 5px;
  padding: 2px;
  line-height: 26px; }

/** COMMUNITY **/
/* community - discussions */
.comm_list {
  line-height: normal;
  margin-top: 7px; }

.comm_list h2 {
  font-size: 20px;
  color: #404040;
  margin-bottom: 18px;
  padding-bottom: 6px;
  border-bottom: 1px solid #bababa;
  font-weight: 700; }

.comm_list h3 {
  font-size: 14px; }

.comm_list ul {
  list-style: none; }

.comm_list li {
  font-size: 17px;
  margin-top: 10px; }

.comm_list li.c {
  font-weight: 700; }

.colab .colabRow {
  border-bottom: 1px solid #D2D1D0; }

.colab .colabRow td {
  padding: 10px 0 10px; }

.colab {
  margin-top: 40px; }

.colab .question {
  width: 405px; }

.colab .replies {
  width: 60px; }

.colab .date {
  width: 60px; }

.colab thead td {
  padding: 0;
  font-size: 15px; }

.colab th.Hdr, .colab .ColGrpHdrRow th {
  height: 27px;
  background: #fb6142;
  border-top: 1px solid #A7D7FB;
  border-bottom: 1px solid #9CBCCA;
  color: white;
  padding-top: 10px;
  padding-left: 5px; }

/* community - members */
.comm_member {
  margin: 40px 0; }

.comm_member table.colab {
  width: 100%;
  margin-top: 0; }

.member table.colab {
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px; }

.comm_member td.r {
  text-align: right; }

.comm_member td.b {
  font-weight: 700; }

.comm_member .memdiv {
  background: #888888;
  height: 4px;
  margin: 7px 0 10px 0; }

#main .comm_member h2 {
  margin: 0; }

.hidden {
  display: none; }

/** PAGER **/
.pager td {
  vertical-align: bottom; }

.paginatorControls {
  padding-left: 20px; }

.pager {
  margin: auto;
  width: 600px;
  height: 20px;
  text-align: right;
  padding-top: 45px; }

/** DISCUSSIONS */
#main {
  float: left;
  width: 680px; }

.right_col {
  float: left;
  width: 200px;
  margin: 0 0 0 50px; }

.right_col.v2 {
  margin-top: 8px; }

#main p {
  line-height: 21px; }

#main h2 {
  margin: 15px 0 7px 0; }

.profilehelp {
  background: url(/images/icon-addprop-arrow.png) no-repeat 0 3px;
  float: right;
  padding: 0 0 5px 11px;
  color: #575757; }

.actionColumn {
  width: 75px;
  font-size: 12px; }

.comm_rightcol-title2 {
  text-transform: uppercase;
  background: #faf6f5;
  padding: 10px 8px;
  font-size: 12px;
  line-height: normal;
  font-weight: 700;
  color: #404040;
  margin-bottom: 5px; }

.comm_member {
  margin: 20px 0 !important; }

.profile_about-head {
  font-weight: bold;
  line-height: 44px; }

.subscriptionSelect {
  text-align: center !important; }

.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden; }

.cmn-toggle ~ label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none; }

input.cmn-toggle-round ~ label {
  padding: 1px;
  width: 60px;
  height: 30px;
  background-color: #dddddd;
  border-radius: 30px;
  margin-right: 10px; }

input.cmn-toggle-round ~ label:before,
input.cmn-toggle-round ~ label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: ""; }

input.cmn-toggle-round ~ label:before {
  right: 1px;
  background-color: #f1f1f1;
  border-radius: 30px;
  transition: background 0.4s; }

input.cmn-toggle-round ~ label:after {
  width: 29px;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: margin 0.4s; }

input.cmn-toggle-round:checked ~ label:before {
  background-color: #fb6142; }

input.cmn-toggle-round:checked ~ label:after {
  margin-left: 30px; }

.profileTable td {
  padding: 3px; }

.profileTable td.points_box {
  border-color: #fb6142;
  border-width: 2px;
  /*border is invisible unless border-style is set*/ }

.profileTable td.left_border {
  border-left-style: solid; }

.profileTable td.right_border {
  border-right-style: solid; }

.profileTable td.bottom_border {
  border-bottom-style: solid; }

.profileTable td.top_border {
  border-top-style: solid; }

td.align_right {
  text-align: right; }

.points-container {
  margin: 0 auto;
  width: 123px;
  height: 123px;
  position: relative; }
  .points-container .fill, .points-container .globe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .points-container .fill {
    background-color: #fb6142;
    transition: background-color 2s ease; }
  .points-container .globe {
    background: url(/images/points/globus.png) no-repeat;
    background-size: cover;
    height: 123px; }

p.arrow-down {
  background: url(/images/points/arrow-down.png) no-repeat;
  background-size: cover;
  width: 35px;
  height: 52px;
  margin: 10px auto 13px auto;
  position: relative; }

td.rightBranch.small .points-container, td.rightBranch.cb-text-sm .points-container, td.rightBranch.small .arrow-down, td.rightBranch.cb-text-sm .arrow-down {
  left: -4.15%; }

td.leftBranch.small .points-container, td.leftBranch.cb-text-sm .points-container, td.leftBranch.small .arrow-down, td.leftBranch.cb-text-sm .arrow-down {
  left: 4.15%; }

.pointType {
  table-layout: fixed; }
  .pointType input {
    max-width: 100%; }
  .pointType label.username-picker {
    display: block;
    margin: 1.5em 0; }
  .pointType p.percentage {
    text-align: center;
    position: relative;
    top: -130px;
    font-size: 2em; }
  .pointType p.info {
    margin-bottom: 0;
    margin-right: 10px; }
  .pointType td.leftBranch {
    background: url(/images/points/arrow-left.png) 69.5% 0; }
  .pointType td.leftBranch.large {
    background-position: 62.5% 0; }
  .pointType td.rightBranch.large {
    background-position: 37.5% 0; }
  .pointType td.rightBranch.small, .pointType td.rightBranch.cb-text-sm {
    background-position: 0 0; }
  .pointType td.leftBranch.small, .pointType td.leftBranch.cb-text-sm {
    background-position: 100% 0; }
  .pointType td.rightBranch {
    background: url(/images/points/arrow-right.png) 30.5% 0; }
  .pointType td.leftBranch, .pointType td.rightBranch {
    background-repeat: no-repeat;
    position: relative;
    top: -50px;
    padding-top: 80px; }
    .pointType td.leftBranch td.leftBranch, .pointType td.leftBranch td.rightBranch, .pointType td.rightBranch td.leftBranch, .pointType td.rightBranch td.rightBranch {
      padding-left: 10px;
      padding-right: 10px;
      top: -30px;
      padding-top: 50px;
      background-size: 35%; }
      .pointType td.leftBranch td.leftBranch p.percentage, .pointType td.leftBranch td.rightBranch p.percentage, .pointType td.rightBranch td.leftBranch p.percentage, .pointType td.rightBranch td.rightBranch p.percentage {
        top: -100px; }
    .pointType td.leftBranch td.leftBranch, .pointType td.rightBranch td.leftBranch {
      background-position: 60% 0; }
    .pointType td.leftBranch td.rightBranch, .pointType td.rightBranch td.rightBranch {
      background-position: 40% 0; }
    .pointType td.leftBranch td td.leftBranch, .pointType td.rightBranch td td.leftBranch {
      background-position: 30% 0; }
    .pointType td.leftBranch td td.rightBranch, .pointType td.rightBranch td td.rightBranch {
      background-position: 70% 0; }
  .pointType ul li {
    padding: 5px 0;
    background: none; }
  .pointType .highlighted {
    color: #73808e;
    font-weight: 700;
    text-align: center; }
  .pointType span.highlighted {
    padding-right: 5px; }
  .pointType .deleteListItem {
    float: right;
    margin: 8px 18px 0 10px;
    padding: 2px 6px;
    background: #fb6142;
    border-radius: 3px;
    color: white;
    cursor: pointer; }
  .pointType .error {
    font-weight: 700;
    color: #b94a48; }

ul.userDistributionInputs {
  list-style-type: none; }
  ul.userDistributionInputs li {
    padding: 0;
    margin: 0;
    background: none;
    clear: both; }
  ul.userDistributionInputs li.header {
    padding-bottom: 10px;
    padding-top: 15px; }
  ul.userDistributionInputs li.error {
    padding-top: 10px; }
  ul.userDistributionInputs .header .percentage {
    padding-left: 30px; }
  ul.userDistributionInputs .header .name {
    padding-left: 39px; }

.totalRow .percentage {
  width: 75px;
  margin-right: 2px;
  display: inline-block;
  text-align: center; }

.totalRow .percentage.smaller {
  width: 51px;
  margin-right: 6px;
  margin-left: 11px; }

ul.subproposals {
  list-style-type: none; }
  ul.subproposals li > a {
    display: inline-block;
    width: 80%; }
  ul.subproposals li > span {
    display: inline-block;
    vertical-align: top; }

.percentageInput input {
  width: 50px;
  margin-left: 5px;
  margin-right: 15px;
  float: none; }

.percentageInput span, .percentageInput input, .percentageInput .input {
  display: inline-block;
  vertical-align: baseline; }

.percentageInput span.input {
  text-align: center;
  height: 24px;
  width: 75px;
  margin-right: 5px;
  margin-left: 0;
  float: none; }

#help-text {
  margin: 1.5em 0;
  padding: 15px;
  background: #faf6f5;
  border-radius: 10px; }

#expand-help-text {
  display: inline-block;
  cursor: pointer;
  width: 31px;
  height: 31px;
  line-height: 25px;
  vertical-align: middle;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  color: black;
  border: 2px solid #fb6142;
  border-radius: 50%; }

.actboxtop {
  width: 984px;
  position: relative;
  z-index: 5;
  font-size: 17px;
  color: #404040;
  line-height: 20px;
  background: url(/images/grid_t.gif) white;
  border: 1px solid #f6f6f6;
  border-radius: 10px;
  padding-bottom: 15px; }

.actboxtop a {
  font-weight: 700; }

.type {
  padding: 16px 0 0 12px; }

.actions_wrap {
  position: relative;
  width: 984px; }

.act_left {
  float: left;
  width: 240px; }

.act_right {
  float: left;
  width: 734px;
  padding-left: 10px;
  color: #404040;
  position: relative; }

.acthead-l {
  background: url(/images/icon_act-arrows-l.png) no-repeat 0 24px;
  padding: 26px 0 22px 47px;
  font-size: 18px;
  color: #404040;
  font-weight: 700; }

.acthead-r {
  background: url(/images/icon_act-arrows-r.png) no-repeat 0 24px;
  padding: 26px 0 22px 47px;
  font-size: 18px;
  color: #404040;
  font-weight: 700; }

.act_left-list {
  clear: left;
  padding-top: 20px; }

.act_left-list li {
  list-style: none;
  font: bold 11px Verdana, sans-serif;
  background: none;
  padding: 0 0 7px 0; }

.act_left-list li a {
  display: inline-block;
  padding: 0 0 0 15px; }

.act_left-list li.d a {
  background: url(/images/tri_down.png) no-repeat 0 2px; }

.act_left-list li.u a {
  background: url(/images/tri_up.png) no-repeat 0 0; }

.act_left-list li ul {
  padding: 10px 0 15px 5px;
  display: none; }

.act_left-list li ul li {
  position: relative;
  padding: 10px;
  margin-left: -10px;
  font: normal 12px/20px Verdana, sans-serif;
  color: #404040;
  border: 1px solid white; }

.act_left-list li ul li:hover {
  background: #faf6f5;
  border: 1px solid #c0887d; }

.act_left-list li.d ul li a {
  font-weight: 700;
  background: none;
  padding: 0; }

.act_left-list li.u ul li a {
  font-weight: 700;
  background: none;
  padding: 0; }

/* tool tips */
#hide_tips {
  display: none; }

.act_tooltip {
  width: 266px;
  background: url(/images/actions_tooltip-top.png) no-repeat right top;
  padding-top: 14px;
  position: absolute;
  left: 245px;
  top: 0;
  z-index: 5;
  visibility: hidden; }

.c-Tooltip__actions__arrow {
  background: url(/images/actions_tooltip-arrow.png) no-repeat 0 8px; }

.c-Tooltip__actions__text {
  width: 258px;
  background: url(/images/actions_tooltip-bg.png);
  margin-left: 8px;
  padding: 0 16px;
  font: 11px/17px Verdana, sans-serif;
  color: white;
  min-height: 30px; }

.c-Tooltip__actions__bottom {
  background: url(/images/actions_tooltip-bottom.png) no-repeat right;
  height: 20px; }

/* charts */
.act_charts-top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  width: 764px;
  height: 7px;
  background: url(/images/actions_chart-shade-t.png) no-repeat right; }

.act_charts-bot {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 7;
  width: 764px;
  height: 7px;
  background: url(/images/actions_chart-shade-b.png) no-repeat right; }

.act_charts-top2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 7;
  width: 984px;
  height: 7px;
  background: url(/images/actions_chart-shade-t2.png) no-repeat right; }

.act_charts-bot2 {
  position: absolute;
  bottom: 46px;
  left: 0;
  z-index: 7;
  width: 984px;
  height: 7px;
  background: url(/images/actions_chart-shade-b2.png) no-repeat right; }

.actions_chart-head {
  width: 734px;
  min-height: 85px;
  padding: 0; }

.actions_chart-head-bg {
  width: 734px;
  padding: 15px;
  background: url(/images/actions_charttop-bg.gif); }

.actions_chart-head-shade {
  position: absolute;
  z-index: 5;
  width: 734px;
  height: 5px;
  background: url(/images/actions_charttop-shade.png) repeat-x; }

.act-edit_left .actions_chart-head-shade {
  width: 480px; }

.actions_chart {
  width: 734px;
  background: url(/images/actions_chart-bg.gif); }

.actions_chart_y-axis {
  float: left;
  width: 50px;
  line-height: normal;
  font: 11px Verdana, sans-serif;
  color: #575757; }

.actions_chart_y-axis li {
  list-style: none;
  padding: 10px 0 0 12px; }

.actions_chart_grid {
  float: left;
  width: 683px;
  height: 380px;
  border-left: 1px solid #bababa;
  border-bottom: 1px solid #bababa;
  background: url(/images/actions_grid.png); }

.actions_chart_x-axis {
  clear: both;
  height: 40px;
  margin-left: 50px;
  line-height: normal;
  font: 11px Verdana, sans-serif;
  color: #575757; }

.actions_chart_x-axis li {
  float: left;
  list-style: none;
  padding: 10px 33px 0 0; }

.actions_key {
  font: 12px Verdana, Geneva, sans-serif;
  margin: 15px 0 0 5px; }

/* actions EDIT page */
.acthead-l.edit {
  padding-bottom: 0;
  height: 40px; }

.acthead-r.edit {
  padding-bottom: 0;
  margin-left: 250px;
  height: 40px; }

.acthead-r .runmodel {
  float: right;
  margin-top: -5px; }

.act-edit_left {
  float: left;
  width: 495px; }

.act-edit_right {
  float: right;
  width: 489px;
  color: #404040; }

.act-edit_left-top {
  width: 480px;
  padding: 15px;
  background: url(/images/actions_charttop-bg.gif); }

.actions-edit_chart-head {
  width: 489px;
  min-height: 40px; }

.actions-edit_chart-head-bg {
  width: 459px;
  padding: 15px;
  background: url(/images/actions_charttop-bg.gif); }

.actions-edit_chart-head-shade {
  position: absolute;
  z-index: 5;
  width: 489px;
  height: 5px;
  background: url(/images/actions_charttop-shade.png) repeat-x; }

.actions-edit_chart {
  padding-top: 15px;
  width: 489px;
  background: url(/images/actions_chart-bg.gif); }

.actions-edit_chart_y-axis {
  float: left;
  width: 50px;
  line-height: normal;
  font: 11px Verdana, sans-serif;
  color: #575757; }

.actions-edit_chart_y-axis li {
  list-style: none;
  padding: 10px 0 0 12px; }

.actions-edit_chart_grid {
  float: left;
  width: 433px;
  height: 380px;
  border-left: 1px solid #bababa;
  border-bottom: 1px solid #bababa;
  background: url(/images/actions_grid.png); }

.actions-edit_chart_x-axis {
  clear: both;
  height: 40px;
  margin-left: 50px;
  line-height: normal;
  font: 11px Verdana, sans-serif;
  color: #575757; }

.actions-edit_chart_x-axis li {
  float: left;
  list-style: none;
  padding: 10px 10px 0 0; }

.chart_sub-menu {
  float: left;
  width: 180px;
  list-style: none;
  margin-top: 15px;
  line-height: normal; }

.chart_sub-menu li, #content .chart_sub-menu li {
  width: 180px;
  vertical-align: middle;
  background: none;
  list-style: none;
  margin-left: 0;
  cursor: pointer; }

.chart_sub-menu li a {
  width: 160px;
  padding: 10px 0 10px 20px;
  margin: 1px 0;
  display: inline-block;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 12px;
  background: url(/images/actions_chart-list-shade.png) repeat-y white; }

.chart_sub-menu li a:hover {
  padding: 11px 0 11px 20px;
  margin: 0;
  background: #bababa;
  color: white; }

.chart_sub-menu li.c a {
  padding: 11px 0 11px 20px;
  margin: 0;
  background: #bababa;
  color: white; }

.chart_sub-description {
  float: left;
  width: 295px;
  margin: 30px 0 0 50px;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 13px;
  line-height: 21px; }

.actions-edit_chart .chart_sub-description {
  width: 210px; }

.chart_sub-description span {
  font-size: 14px;
  font-weight: 700; }

.actions-edit_key {
  font: 12px Verdana, sans-serif;
  margin: 15px 0 15px 5px; }

.actions-edit_key-top {
  float: right;
  font: 12px Verdana, sans-serif;
  margin: 0 0 10px 0; }

.act-edit-box_left {
  float: left;
  width: 455px;
  margin-right: 10px;
  background: #f6f6f6;
  padding: 17px 15px 6px 15px;
  font-size: 15px;
  color: #444; }

.act-edit-box_right {
  float: left;
  width: 459px;
  background: #f6f6f6;
  padding: 17px 15px 6px 15px;
  font-size: 15px;
  color: #444; }

.model_upload {
  border: none;
  background: url(/images/model_upload-button.gif) no-repeat;
  width: 133px;
  height: 34px;
  cursor: pointer; }

.model_upload #filepc {
  opacity: 0.0;
  -moz-opacity: 0.0;
  filter: alpha(opacity=0);
  width: 133px;
  height: 34px;
  cursor: pointer; }

.control_title {
  font: 12px Verdana, sans-serif;
  color: black;
  margin-top: 15px; }

.control_title span {
  font-size: 18px;
  font-weight: 700;
  color: #73808e; }

.control_wrap {
  font: 11px Verdana, sans-serif;
  color: #888888;
  line-height: 14px;
  height: 38px;
  margin: 12px 0; }

.control_slider {
  float: left;
  width: 113px;
  height: 20px;
  background: url(/images/control-track.gif) no-repeat 0 10px;
  margin-right: 10px; }

.control_slider img {
  margin-bottom: 4px; }

.slider1 {
  margin-left: 20px; }

.slider2 {
  margin-left: 40px; }

.slider3 {
  margin-left: 60px; }

.control_amt1 {
  float: left;
  width: 96px; }

.control_amt2 {
  float: left;
  width: 92px; }

.control_amt3 {
  float: left;
  width: 60px; }

.control_t1 {
  float: left;
  width: 37px;
  padding-top: 5px; }

.control_t2 {
  float: left;
  width: 47px;
  padding-top: 5px; }

.control_input {
  background: #f6f6f6;
  border: 1px solid #bababa;
  width: 53px;
  height: 34px;
  padding-right: 6px;
  text-align: right;
  font-size: 18px;
  color: black; }

.control_div {
  clear: both;
  height: 2px;
  background: #f6f6f6;
  margin-bottom: 10px; }

.act_tooltip {
  display: none; }

.rounded_button {
  float: left;
  text-align: center;
  height: 26px;
  margin: 0 7px 7px 0;
  font: 11px Verdana, Geneva, sans-serif;
  line-height: normal;
  border-radius: 5px; }

.rounded_button a {
  height: 20px;
  padding: 6px 10px 0 10px;
  display: inline-block;
  line-height: normal;
  border-radius: 5px; }

.rounded_button.blugrid a {
  background: #fb6142;
  color: white;
  border: 1px solid #c0887d; }

.rounded_button.blugrid a:hover {
  background: white;
  color: #c0887d; }

.rounded_button.blugrid.c a {
  background: white;
  color: #c0887d; }

.ui-slider {
  font-family: Verdana, Arial, sans-serif;
  font-size: 1.1em;
  height: .8em;
  position: relative;
  background: url(/images/control-track.gif) repeat-x 0 50%;
  border: 0;
  margin-top: 7px;
  margin-right: 5px;
  margin-left: 5px; }

.ui-widget-content a.ui-slider-handle {
  position: absolute;
  z-index: 2;
  top: -5px;
  width: 11px;
  height: 20px;
  overflow: hidden;
  margin-left: -6px;
  background: #fb6142;
  cursor: pointer;
  border-radius: 4px;
  border: 0; }

.ui-widget-content a.ui-slider-handle-active, .ui-widget-content a.ui-slider-handle-active:hover {
  width: 11px;
  height: 16px;
  position: absolute;
  z-index: 2;
  margin-left: -0.6px;
  top: -3px;
  overflow: hidden;
  background: #fb6142; }

.ui-slider-range {
  height: .8em;
  background: url(/images/slider_spanBG.gif) 0 50% repeat-x;
  position: absolute; }

.ui-dialog {
  font-size: .9em;
  background: white url(/images/slider_bg.gif) 0 0 repeat-x;
  color: black;
  border: 4px solid #f6f6f6;
  position: relative; }

.chartContainer .jqplot-base-canvas {
  background-color: transparent; }

.control_div {
  margin-top: 10px;
  margin-right: 20px; }

.control_definition {
  font: 11px Verdana, sans-serif;
  color: #888888; }

#content input.control_input {
  padding: 0;
  padding-right: 6px;
  text-align: right;
  border: none;
  font: inherit;
  font-size: 16px;
  color: black; }

.act_charts-bot {
  bottom: 0; }

.actInputDef {
  position: relative; }

.act_left-list li ul {
  display: list-item; }

.act_left-list li.act_left-list-child {
  border: 1px solid white; }

.act_left-list li.act_left-list-child:hover {
  background: #faf6f5;
  border: 1px solid #c0887d; }

tr.jqplot-table-legend {
  position: relative; }

td.jqplot-table-legend div.act_tooltip_label {
  width: 275px;
  background: url(/images/actions_tooltip-top.png) no-repeat left top;
  padding: 0;
  padding-top: 14px;
  position: absolute;
  left: -262px;
  top: -20px;
  z-index: 500; }

td.jqplot-table-legend div.act_tt-wrap_label {
  background: url(/images/actions_tooltip-arrow_label.png) no-repeat 258px 8px; }

td.jqplot-table-legend div.act_tt-txt_label {
  width: 258px;
  background: url(/images/actions_tooltip-bg.png);
  padding: 0 16px;
  font: 11px/17px Verdana, sans-serif;
  color: white; }

td.jqplot-table-legend div.act_tt-bot_label {
  background: url(/images/actions_tooltip-bottom.png) no-repeat left;
  height: 20px; }

td.jqplot-table-legend a:hover {
  color: #fec5ba; }

#impact .shaded-bg {
  background: url(/images/pattern-6x6-blue.png); }

.p-Homepage #content {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  max-width: initial; }

.p-Homepage h1 {
  font-size: 2.1875rem; }
  @media (max-width: 767.98px) {
    .p-Homepage h1 {
      font-size: 1.6875rem; } }

.p-Homepage h2 span {
  font-size: 1rem;
  margin-left: .5rem; }

.p-Homepage__homespot {
  margin-bottom: 1.5rem;
  background: transparent; }

.p-Homepage__homespot__background {
  background: url(/images/homespot/background.png) right top no-repeat; }

@media (min-width: 576px) {
  .p-Homepage__homespot__background-sm {
    background: url(/images/homespot/background.png) right top no-repeat; } }

@media (min-width: 768px) {
  .p-Homepage__homespot__background-md {
    background: url(/images/homespot/background.png) right top no-repeat; } }

@media (min-width: 992px) {
  .p-Homepage__homespot__background-lg {
    background: url(/images/homespot/background.png) right top no-repeat; } }

@media (min-width: 1200px) {
  .p-Homepage__homespot__background-xl {
    background: url(/images/homespot/background.png) right top no-repeat; } }

/* ABOUT ------------------------------------------------------------------------------------- */
.deciding {
  margin-top: 40px;
  margin-bottom: 20px; }

.breaking {
  background: url(/images/about/how-break-1_small.jpg) no-repeat;
  margin-top: 19px;
  width: 460px;
  padding: 0 0 0 260px;
  margin-bottom: 48px; }

.breaking2 {
  padding: 30px 0 0 12px; }

.putting {
  background: url(/images/about/how-putting.jpg) no-repeat;
  margin: 35px 0 40px 0;
  width: 365px;
  height: 179px;
  padding: 40px 0 0 310px; }

.putting img {
  float: left;
  margin-right: 40px; }

.continue {
  text-align: center; }

.continue a {
  padding: 28px 24px 24px;
  display: inline-block;
  background: #faf6f5;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  border-radius: 12px; }

.contribute {
  min-height: 165px;
  background: url(/images/about/getinvolved-top_illustration.png) no-repeat;
  padding: 34px 0 0 320px;
  margin-top: 55px; }

.getinvolved-img {
  height: 118px;
  margin-top: 56px;
  position: relative;
  z-index: 5; }

.ge1, .ge2, .ge3, .ge4 {
  height: 118px;
  float: left; }

.ge1 {
  background: url(/images/about/getinvolved-row-01.png) no-repeat 50px 15px;
  width: 182px; }

.ge2 {
  background: url(/images/about/getinvolved-row-02.png) no-repeat 45px 0px;
  width: 180px; }

.ge3 {
  background: url(/images/about/getinvolved-row-03.png) no-repeat 40px 0px;
  width: 182px; }

.ge4 {
  background: url(/images/about/getinvolved-row-04.png) no-repeat 45px 15px;
  width: 148px; }

.getinvolved {
  background: white;
  padding: 42px 22px 2px;
  margin-top: -40px; }

.getinvolved:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.getinvolved {
  display: inline-block; }

* html .getinvolved {
  height: 1%; }

.getinvolved {
  display: block; }

.getinvolved h4 {
  font-size: 22px;
  color: black;
  margin-bottom: 10px;
  text-transform: uppercase; }

.ge1b, .ge2b, .ge3b, .ge4b {
  float: left;
  font-size: 14px;
  line-height: normal; }

.ge1b {
  width: 162px;
  margin-right: 20px; }

.ge2b {
  width: 142px;
  margin-right: 38px; }

.ge3b {
  width: 164px;
  margin-right: 18px; }

.ge4b {
  width: 148px; }

.checkout-wrap {
  border: 1px dashed #888888;
  width: 553px;
  padding: 5px;
  margin: 52px auto 0; }

.checkout {
  background: url(/images/pattern-6x6-white.png) #bababa;
  padding: 24px 16px 26px 32px;
  font-size: 17px;
  line-height: normal; }

.checkout p {
  padding-right: 55px;
  background: url(/images/about/continue-arrow.png) no-repeat right center;
  margin: 0; }

.checkout a {
  font-weight: 700; }

.p-About__approachIllustration {
  margin-top: 30px;
  background: #f6f6f6;
  border-top: 1px solid #888888;
  border-bottom: 1px solid #888888; }
  .p-About__approachIllustration h3 {
    text-align: center;
    text-transform: uppercase; }
  .p-About__approachIllustration p {
    text-align: center;
    font-weight: 400; }
  .p-About__approachIllustration img {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .p-About__approachIllustration .p-About__approachIllustration__col {
    display: inline-block;
    width: 230px;
    padding-bottom: 30px; }
    .p-About__approachIllustration .p-About__approachIllustration__col:first-of-type {
      margin-left: 15px; }

/* CollectionCardsView */
.c-Headline .AdvancedView {
  float: right;
  height: 34px;
  line-height: 34px;
  margin-right: 55px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase; }
  .c-Headline .AdvancedView > div {
    float: left;
    padding-left: 35px; }
  .c-Headline .AdvancedView .outline {
    background: url(/images/icon-list_outline-off.png) no-repeat 6px 8px; }
    .c-Headline .AdvancedView .outline.selected {
      background: url(/images/icon-list_card-on.png) no-repeat 6px 9px; }

.c-Headline .showOptions {
  margin-right: 40px; }

.contests-container {
  background-color: #faf6f5;
  padding-top: 15px;
  padding-left: 35px;
  overflow: hidden; }

.c-ontology-selector {
  height: 50px; }
  .c-ontology-selector .c-arrow {
    float: left;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 32px solid transparent;
    margin-right: 50px; }
    .c-ontology-selector .c-arrow.selected {
      border-bottom: 32px solid #faf6f5;
      background-color: white; }
  .c-ontology-selector a {
    font-weight: bold;
    margin-right: 50px; }
    .c-ontology-selector a.selected {
      color: #888888; }
  .c-ontology-selector .filter {
    margin-right: 65px; }

.c-breadcrump {
  font-size: 14px;
  background-color: #faf6f5;
  padding: 17px;
  padding-bottom: 0;
  padding-left: 20px; }
  .c-breadcrump span {
    color: #888888; }
  .c-breadcrump a {
    color: #fb6142;
    text-decoration: none; }

.c-display-selector {
  background-color: #faf6f5;
  height: 65px; }
  .c-display-selector .show-active {
    float: right;
    text-align: right;
    position: relative;
    font: 12px "Lucida Grande", Arial, sans-serif;
    margin-right: 65px;
    margin-top: 25px;
    padding: 0 16px 0 0; }
  .c-display-selector .view > div {
    width: 70px;
    text-align: right;
    padding-right: 5px;
    margin: 15px;
    float: left;
    height: 34px;
    line-height: 34px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase; }
    .c-display-selector .view > div.selected {
      color: #888888;
      border: solid 1px #fb6142;
      border-radius: 3px;
      margin: 14px; }
  .c-display-selector .view .grid {
    background: url(/images/icon-grid_view-off.png) no-repeat 8px 8px; }
    .c-display-selector .view .grid.selected {
      background: url(/images/icon-grid_view-off.png) no-repeat 8px 8px white; }
  .c-display-selector .view .list {
    background: url(/images/icon-list_view-off.png) no-repeat 6px 8px; }
    .c-display-selector .view .list.selected {
      background: url(/images/icon-list_view-off.png) no-repeat 6px 8px white; }

.c-description {
  margin-right: 400px;
  color: #888888;
  font-size: 15px;
  line-height: 24px; }

/* NOT CollectionCardView */
/* columns  ------------- */
.contest-left {
  float: left;
  width: 500px; }

.contest-right {
  float: left;
  width: 484px; }

.p-Contests__viewSelector {
  background: #f6f6f6; }
  .p-Contests__viewSelector .btn, .p-Contests__viewSelector .c-TitleBar__actionButton {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 700; }

.list a {
  width: 100%; }

.grid a {
  width: 100%; }

.filter input {
  width: 170px;
  height: 27px;
  padding: 1px 0 0 8px;
  border: 1px solid #bababa;
  font: 12px "Lucida Grande", Arial, sans-serif; }

.users-pagination {
  float: left;
  width: 100%;
  border-bottom: 1px solid #bababa;
  padding-bottom: 40px;
  padding-top: 40px; }

.p-Contests__featuredSeparator {
  margin: 0 auto 1.5rem auto;
  width: 75%; }
  @media (min-width: 992px) {
    .p-Contests__featuredSeparator {
      margin-top: 1.5rem;
      margin-bottom: 3rem;
      width: 50%; } }

/* the table with rows of users  */
.userslist {
  clear: both;
  margin-top: -4px; }

.userslist td {
  height: 30px;
  padding: 11px 10px 0;
  font-size: 14px;
  line-height: 20px;
  color: #404040;
  border-bottom: 1px solid #f6f6f6; }

.userslist a {
  font-weight: 700; }

.userslist tr.top td {
  height: 61px;
  padding-top: 15px; }

.userslist td.g {
  background: #f6f6f6; }

.userslist.featured td {
  background-color: #f6f6f6; }

.userslist tr.feat-bot {
  border-bottom: 1px solid #d2aba3; }

#contestsFilterClear {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
  display: block; }

.accordion, .accordion li {
  list-style: none; }

.nothingFound {
  text-align: center;
  padding: 30px;
  text-overflow: ellipsis;
  width: 850px;
  color: #888888; }

/* OUTLINE VIEW - added 1-24-2014 */
.contest-outline-left {
  min-height: 600px;
  padding-bottom: 1.5rem; }

.contest-outline-right {
  padding-bottom: 1.5rem; }

.contest-outline-right div.ctest {
  width: 425px;
  padding-bottom: 5px; }

/* left side */
.expand {
  float: left;
  font: 12px "Lucida Grande", Arial, sans-serif;
  padding: 15px 0 0 0; }

.contest-count {
  display: inline-block;
  font: 12px "Lucida Grande", Arial, sans-serif;
  color: #888888;
  margin-right: 20px;
  padding: 10px 0 20px 10px; }

.contest-count span.count {
  font: 16px "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: black;
  padding: 10px 0 20px 10px; }

.contest-outline-left .contest-count {
  float: right;
  text-align: right; }

.contest-outline-left ul,
.contest-outline-left ul li,
.contest-outline-left li a {
  margin-bottom: 0; }

.contest-outline-left ul {
  margin-bottom: 0; }

.contest-outline-left ul li {
  background: none;
  padding: 0;
  font-size: 12px; }

.contest-outline-left ul.level1 li {
  padding-left: 0; }

.contest-outline-left ul.level2 li {
  padding-left: 32px;
  font-size: 12px; }

.contest-outline-left ul.level3 li {
  padding-left: 32px; }

.contest-outline-left ul.level4 li {
  padding-left: 32px; }

.contest-outline-left li a {
  border-bottom: 1px solid #ccc;
  display: inline-block;
  width: 100%;
  color: black;
  box-shadow: inset -14px 0 10px -10px #ccc; }

.contest-outline-left li a:hover, .section-head.open {
  background-color: #faf6f5; }

.contest-outline-left ul.level1 li a {
  padding: 10px 20px 10px 46px;
  font-size: 14px; }

.contest-outline-left ul.level2 li a {
  padding: 10px 20px 10px 40px;
  font-size: 12px; }

.contest-outline-left ul.level3 li a {
  padding: 10px 20px 10px 35px; }

.contest-outline-left ul.level1 a span {
  width: 90%;
  display: inline-block; }

.contest-outline-left ul.level1 a .count {
  float: right;
  width: 19px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  color: #888888; }

.section-head {
  background: #f6f6f6 url(/images/pattern-6x6-white.png);
  padding: 15px 20px 15px 15px; }

.contest-section1 .section-head {
  color: #efb519; }

.contest-section2 .section-head {
  color: #73808e; }

.contest-section3 .section-head {
  color: #fb6142; }

.contest-section4 .section-head {
  color: #66b035; }

.section-head h2 {
  font-size: 25px;
  display: inline-block; }

.section-head h2 span {
  font-size: 13px;
  font-weight: 200;
  color: #888888;
  vertical-align: middle; }

.section-head .active-icon {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-right: 14px;
  float: left;
  margin-top: 5px; }

.contest-section1 .active-icon {
  background-image: url(/images/contest-icon-cplus-orange.png); }

.contest-section1 .open .active-icon {
  background-image: url(/images/contest-icon-cminus-orange.png); }

.contest-section2 .active-icon {
  background-image: url(/images/contest-icon-cplus-green.png); }

.contest-section2 .open .active-icon {
  background-image: url(/images/contest-icon-cminus-green.png); }

.contest-section3 .active-icon {
  background-image: url(/images/contest-icon-cplus-blue.png); }

.contest-section3 .open .active-icon {
  background-image: url(/images/contest-icon-cminus-blue.png); }

.contest-section4 .active-icon {
  background-image: url(/images/contest-icon-cplus-red.png); }

.contest-section4 .open .active-icon {
  background-image: url(/images/contest-icon-cminus-red.png); }

.section-head .section-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  float: right; }

.contest-section1 .section-icon {
  background-image: url(/images/contest-icon-what.png);
  margin-top: 7px; }

.contest-section2 .section-icon {
  background-image: url(/images/contest-icon-where.png);
  margin-top: 5px; }

.contest-section3 .section-icon {
  background-image: url(/images/contest-icon-who.png);
  margin-top: 3px; }

.contest-section4 .section-icon {
  background-image: url(/images/contest-icon-how.png);
  margin-top: 3px; }

.level1 li.anything a.open {
  background-color: #ec2c05; }

.level1 li a {
  background: url(/images/contest-icon-plus.png) no-repeat 18px center; }

.level1 li.active > a {
  color: white;
  background-color: #ec2c05; }

.level1 li a.open {
  background-image: url(/images/contest-icon-minus-orange.png); }

.level1 li.active a.open {
  background-image: url(/images/contest-icon-minus.png); }

.level1 li.active a.open.active .count {
  color: white; }

.level1 li.no-child a, .level1 li.no-child a.open {
  background-image: none; }

.level1 li.anything a {
  background: white url(/images/pattern-6x6-lite.png) repeat top left; }

/* Right side */
.cintro {
  float: right;
  width: 320px;
  margin-right: 60px; }

.cintro {
  background: white;
  text-align: center;
  padding: 15px 0;
  line-height: 24px;
  color: #888888; }

.cintro h2 {
  margin-bottom: 24px;
  color: black; }

.cintro strong {
  color: #575757; }

.contest-outline-right .contest-count {
  font: 18px "proxima-nova", Arial, Helvetica, sans-serif;
  color: #888888;
  padding: 0 0 0 0; }

.contest-outline-right .contest-count span {
  font-size: 18px; }

.contest-count.plural .plural, .contest-count.singular .singular,
.other-contest-count.plural .plural, .other-contest-count.singular .singular {
  display: inline; }

.contest-count.singular .plural, .contest-count.plural .singular,
.other-contest-count.singular .plural, .other-contest-count.plural .singular {
  display: none; }

.other-contest-count {
  font: 13px "Lucida Grande", Arial, sans-serif;
  color: #888888;
  margin-right: 20px;
  padding: 10px 0 20px 10px; }

.other-contest-count span.count {
  font: 14px "proxima-nova", Arial, Helvetica, sans-serif;
  padding: 10px 0 20px 0; }

.section-list {
  margin-bottom: 25px;
  margin-top: 47px; }

.slevel1 {
  background: #e0dacb;
  margin-left: 0; }

.slevel2 {
  background: #d1cbbf;
  margin-left: 9px; }

.slevel3 {
  background: #c1bcb2;
  margin-left: 16px; }

.slevel4 {
  background: #aaa69f;
  margin-left: 21px; }

.slevel5 {
  background: #aaa69f;
  margin-left: 23px; }

.slevel6 {
  background: #aaa69f;
  margin-left: 25px; }

.contest-list {
  font-size: 14px;
  margin-left: 30px;
  overflow-y: auto;
  overflow-x: hidden; }

.prop-head {
  text-align: right;
  font: 10px "Lucida Grande", Arial, sans-serif;
  color: #888888;
  padding: 0 0 10px 0; }

.spacr {
  background-color: white;
  padding-left: 4px; }

.slevel1 .spacr {
  margin-left: 30px; }

.slevel2 .spacr {
  margin-left: 21px; }

.slevel3 .spacr {
  margin-left: 14px; }

.slevel4 .spacr {
  margin-left: 9px; }

.slevel5 .spacr {
  margin-left: 6px; }

.slevel6 .spacr {
  margin-left: 3px; }

.txt {
  background-color: #f6f6f6;
  padding: 10px;
  border-top: 1px solid #888888; }

.section a {
  display: block;
  text-decoration: none;
  color: black;
  cursor: pointer; }

.section {
  position: relative; }

.section.active .txt {
  background-color: #ec2c05;
  color: white;
  border-top: 1px solid white; }

.section.active:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 40px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ec2c05; }

.contest-list a.trigger {
  background: url(/images/contest-arrow-dwn.png) no-repeat 0 11px;
  width: 20px;
  display: inline-block;
  height: 20px; }

.contest-list a.contestName {
  width: 355px;
  padding: 5px 0 5px 0; }

.contest-list .viewContestDetails a {
  background: none;
  padding: 0; }

.contest-list a.trigger.active {
  background-image: url(/images/contest-arrow-up.png); }

.contest-list a .count {
  float: right;
  background: url(/images/contest-icon-prop.png) no-repeat;
  width: 24px;
  padding-left: 24px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 700;
  color: #888888; }

.detail {
  font-size: 12px;
  margin: 0 0 15px 29px;
  padding: 5px 0;
  color: #888888;
  line-height: 19px;
  border-bottom: 1px solid #f6f6f6;
  display: none; }

.detail p {
  margin: 0 0 10px 0; }

.what, .where, .who, .how {
  float: left;
  font-weight: 900; }

.what {
  color: #efb519; }

.where {
  color: #73808e; }

.who {
  color: #fb6142; }

.how {
  color: #66b035; }

.detail .how, .detail .what, .detail .where, .detail .who {
  width: auto;
  background: none;
  display: inline-block;
  margin-right: 5px;
  padding: 0; }

.contest-outline-left li ul {
  display: none; }

/** just to hide outline as it's in beta stage **/
.c-Headline .outline {
  display: inline; }

.contest-outline-left ul.level1 a .count.zero {
  color: #ccc; }

.otherContestsCount.plural .plural, .otherContestsCount.singular .singular {
  display: inline; }

.otherContestsCount.singular .plural, .otherContestsCount.plural .singular {
  display: none; }

.otherContestsInfo {
  margin-top: 10px; }

/* PROPOSALS ----------------------------------------------------------------------------- */
/* proposal HEADER */
.proposal-head {
  margin-bottom: 1rem; }

.proposal-head h1 {
  color: #fb6142;
  font-size: 1.5rem; }
  @media (min-width: 576px) {
    .proposal-head h1 {
      font-size: 1.75rem; } }

/* edit proposal block */
.lastedited {
  float: left;
  height: 19px;
  font-size: 13px; }

.prop-left h2 {
  font-size: 27px;
  color: #404040;
  margin: 10px 0 16px;
  text-align: justify;
  text-transform: none;
  font-weight: initial; }

.prop-left h3 {
  font-size: 22px;
  margin-bottom: 13px;
  color: #404040; }

.prop-left p {
  color: #575757; }

/*  the Table-like rows of proposals */
.propbox {
  clear: both;
  padding: 15px 0px 30px 14px;
  border-bottom: 1px solid #f6f6f6; }

.propbox.featured {
  background: #f6f6f6;
  border-bottom: 1px solid #bababa; }

.propbox.top {
  margin-top: -4px; }

.propbox .row1 {
  height: 38px;
  padding-top: 7px; }

.propbox .row2 {
  font-size: 13px;
  color: #404040;
  clear: both; }

.propbox .title-author {
  float: left;
  width: 602px;
  margin-top: 12px; }

.propbox.featured .title-author {
  float: left;
  width: 602px;
  margin-top: 12px; }

.propbox.completed .title-author {
  width: 752px; }

.propbox.featured.completed .title-author {
  width: 722px; }

.propbox h4 {
  float: left;
  display: inline;
  font-weight: 700;
  color: #404040; }

.propbox-red {
  background-color: #b94a48 !important; }

.propbox-green {
  background-color: #a7da85 !important; }

/* Proposal sidebar */
.p-Proposal__sidebar__subscribe {
  text-transform: none; }

.p-Proposal__sidebar__section {
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
  font-size: 15px; }
  .p-Proposal__sidebar__section a {
    font-weight: 700; }

.p-Proposal__sidebar__section--background {
  background: white;
  border-top: 1px solid #bababa;
  border-bottom: 1px solid #bababa; }

.p-Proposal__sidebar__heading {
  font-size: .8125rem;
  color: #888888;
  border-top: 1px dotted #888888;
  font-weight: 700;
  text-transform: uppercase; }

/* contributors table */
.contributors td {
  color: #575757;
  padding: 6px 10px;
  vertical-align: middle; }

.contributors td.big {
  padding-top: 12px; }

.contributors td img {
  margin-right: 12px; }

.contributors tr.odd td {
  background: #f6f6f6; }

.contributors tr.bottom td {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px; }

.p-Proposal__formBox__label {
  display: flex;
  flex-direction: row; }
  @media (max-width: 991.98px) {
    .p-Proposal__formBox__label {
      justify-content: space-between; } }

.p-Proposal__formBox__helpText::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.p-Proposal__formBox__helpText::after {
  display: none; }

.p-Proposal__formBox__helpText::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.p-Proposal__formBox__helpText:empty::after {
  margin-left: 0; }

.p-Proposal__formBox__helpText:after, .p-Proposal__formBox__helpText:before {
  vertical-align: 0.1em; }

.proposals #contactForm {
  display: block; }

.blue-arrow-right {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(/images/arrows.png) 18px 0;
  vertical-align: top; }

.blue-arrow-left {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(/images/arrows.png) 0 0;
  vertical-align: top; }

#proposalPickerTable td {
  padding: 10px; }

/* container */
.thread-ribbon-container {
  position: absolute;
  right: -5px;
  top: -5px;
  overflow: hidden;
  height: 120px;
  padding-top: 15px;
  line-height: normal;
  font-size: 10px;
  pointer-events: none; }
  .thread-ribbon-container > div {
    background: #fb6142;
    padding: 3px;
    width: 185px;
    color: white;
    position: relative;
    right: -70px;
    text-align: center;
    transform: rotate(45deg); }
  .thread-ribbon-container:before {
    content: '';
    position: absolute;
    left: 109px;
    top: 0;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fa401a; }
  .thread-ribbon-container:after {
    content: ' ';
    position: absolute;
    right: 0;
    bottom: 53px;
    width: 0;
    height: 0;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    border-left: 5px solid #fa401a; }

.ui-widget-content a {
  color: #fb6142; }

.historyTable {
  font-weight: 700; }

.historyTable td {
  padding: 5px; }

#proposalPickerTable .ui-datatable-odd {
  background-color: #f6f6f6; }

.historyTable .ui-datatable-highlighted a, .ui-datatable-highlight a {
  color: #73808e !important; }

.historyTable table {
  width: 617px;
  margin-top: 10px; }

.ui-datatable table {
  width: auto; }

.ui-widget-header {
  background: none; }

.propbox.judgement-actions.featured .title-author {
  width: 415px; }

.propbox.judgement-actions .title-author {
  width: 445px; }

.p-Proposals__proposalPicker__scrollContainer {
  height: 300px;
  overflow: auto;
  position: relative; }

/** Contests list **/
.contestlist.table .column.contestName {
  width: 366px; }

.contestlist.table .column.contestName .contestFlag {
  position: relative; }

.contestlist.table .column.contestProposalsCount {
  width: 31px; }

.contestlist.table .column.contestCommentsCount {
  width: 35px; }

.contestlist.table .column.contestWhat {
  width: 118px; }

.contestlist.table .column.contestWhere {
  width: 105px; }

.contestlist.table .column.contestWho {
  width: 105px; }

.contestlist.table .column.contestHow {
  width: 105px; }

/** PROPOSAL DETAILS **/
.contributors td, .judgingForm td {
  width: 525px; }

/** edit proposal sections **/
#proposalPitchInput {
  height: 100px; }

.judgingForm tr.odd td {
  background: #f2f1e9; }

.judgingForm td {
  text-align: center;
  vertical-align: middle; }

#copyProposalPopupContent tr:nth-child(even) {
  background-color: #f3f8fa; }

#copyProposalPopupContent td {
  padding: 5px; }

#copyProposalPopupContent td .sectionContent {
  display: none; }

#copyProposalPopupContent td .hideContent {
  display: none; }

#copyProposalPopupContent .sectionPresent {
  font-size: 0.8em;
  color: #FF4444; }

.c-Box.empty .copyFromBaseProposalTriggerContainer {
  display: block; }

.c-Box.notempty .copyFromBaseProposalTriggerContainer {
  display: none; }

div.review-status-container {
  position: relative;
  display: inline-block;
  margin-top: 10px; }

div.judge-review-status {
  float: left;
  display: inline;
  margin: 8px;
  text-align: center;
  width: 126px; }

img.review-status-icon {
  width: 15px;
  position: absolute;
  top: -7px;
  right: -7px; }

#judge-evaluation-criteria {
  margin-top: 10px; }

#judge-evaluation-criteria span.help-text {
  font-style: italic;
  font-size: 9pt;
  margin: 5px 0 0 20px; }

table.judgingForm {
  margin-bottom: 1em;
  margin-top: 0.5em; }

.judging_comments {
  clear: both; }

.judging_comments .comment {
  width: 49%;
  display: inline-block; }

.judging_comments .comment .c-Comment__commentor, .judging_comments .comment .c-Comment {
  display: inline-block;
  vertical-align: top; }

.judging_comments .comment .c-Comment__commentor {
  width: 30%; }

.judging_comments .comment .c-Comment {
  width: 70%; }

.judging_comments h2 {
  margin: 0.5em 0; }

.submitStatus {
  margin-top: 1em; }

.submitStatus.error {
  color: #900; }

#advanceDecision {
  width: 100%;
  font-size: 14px;
  color: black; }

#proposalPicker_proposalsContainer h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0; }

#proposalPicker_proposalsContainer .propbox ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none; }

#proposalPicker_proposalsContainer .propbox li {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  background: none; }

#proposalPicker_proposalsContainer .propbox .row2 {
  width: 650px; }

#proposalPicker_proposalsContainer .higlightText1,
#proposalPicker_contestsContainer .higlightText1 {
  background: #fdff62;
  font-style: italic; }

#proposalPicker_proposalsContainer .c-Tooltip .higlightText1,
#proposalPicker_contestsContainer .c-Tooltip .higlightText1 {
  background: none;
  text-decoration: none;
  font-style: normal; }

.box_tooltip {
  display: inline;
  position: relative; }

.box_tooltip:hover:after {
  background: #333;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  bottom: 26px;
  color: #fff;
  content: attr(title);
  left: 20%;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 220px; }

.box_tooltip:hover:before {
  border: solid;
  border-color: #333 transparent;
  border-width: 6px 6px 0 6px;
  bottom: 20px;
  content: "";
  left: 50%;
  position: absolute;
  z-index: 99; }

.greyed_out {
  color: #aaa; }

.proposalPicker_contestsScrollContainer {
  height: 300px;
  overflow-y: scroll; }

div.selected {
  background-color: #eaf2fb; }

.wizardSelectedOptionsInfo li {
  font-weight: normal !important; }

.modelSelector {
  background: #e8e7dc;
  padding: 10px; }

#portlet-preferences h2 {
  margin: 1em 0; }

/* fix problem with the tooltip library, where tooltips overlay the phase names */
.contestbar .phaseLinkContainer div.c-Tooltip {
  top: -102px !important; }

.dd-options li {
  margin-left: 0 !important;
  padding: inherit !important;
  background: none !important; }

/* Impact tab */
#impact table td, #impact table th {
  padding: 10px;
  max-width: 200px; }

#impact th.blue-text {
  color: #30a3fb; }

#impact .blue-bg {
  font-size: 13px;
  color: white;
  background: #30a3fb;
  border: 2px white solid; }

#impact .impact-value {
  font-weight: bold;
  text-align: center;
  border-left: 2px black solid; }

#impact :not(tr:last-child) .impact-value {
  border-right: 2px black solid; }

#impact-series-detail table#edit-table tr {
  border-left: 2px black solid; }

#impact-series-detail table#edit-table thead th,
#impact table#impact-summary,
#impact-series-detail table#edit-table {
  border-left: 2px solid black;
  border-right: 2px solid black; }

#impact-series-detail table#edit-table tbody,
#impact-series-detail table#edit-table tbody,
#impact table#impact-summary {
  border-bottom: 2px solid black; }

#impact tr.selected {
  background-color: #e0e0e0; }

#impact tr select {
  margin: 0;
  width: 202px; }

#impact table#impact-summary tbody {
  max-height: 350px;
  overflow: auto; }

#impact input {
  width: 30px;
  text-align: center;
  padding: 5px; }

#impact input.error {
  border: 2px solid red; }

#impact td.shaded-bg {
  text-align: center; }

#impact table#impact-series-edit span {
  margin: 0 auto;
  padding-left: 5px; }

#impact-chart {
  display: block;
  width: 500px;
  margin: 0 auto 20px auto; }

#impact-series-detail div#new-series {
  vertical-align: middle;
  display: table-cell; }

#impact-series-detail div#new-series select {
  margin-right: 15px;
  width: 21.5%;
  height: 32px; }

#impact-series-detail div#c-Header {
  padding-top: 15px;
  vertical-align: middle;
  display: table-cell; }

#impact-series-detail div#c-Header h3 {
  margin-right: 10px;
  display: inline-block; }

#impact div#sgrip {
  bottom: -5px;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border: 1px solid #000000; }

#impact table .sector {
  padding-left: 20px; }

#impact table .model.total {
  background-color: #66af45; }

#impact table .bold {
  font-weight: bold; }

#impact table .italic {
  font-style: italic; }

#impact-summary tr {
  opacity: 1.0; }

.subcontests {
  padding-bottom: 30px;
  padding-top: 20px; }

tr.impact-series-clickable:hover,
tr#impact-new-series-row:hover {
  background-color: #eaf2fb; }

.subcontests .title {
  font-size: 16px;
  color: #66af45; }

.subcontests ul {
  margin-left: 0;
  width: 100%;
  overflow: hidden; }

.subcontests li {
  margin-left: 0;
  width: 49%;
  line-height: 1.5em;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block; }

.red-text {
  color: red; }

span.stars, span.stars span {
  display: block;
  background: url(/images/stars.png) 0 -16px repeat-x;
  width: 80px;
  height: 16px; }

span.stars span {
  background-position: 0 0; }

.impact-value {
  background-color: #b9b8b3; }

.sector.total {
  background-color: #666666; }

.sector.model {
  background-color: #66af45; }

.modelSettingsSelectBox input[type=radio] {
  margin-right: 5px; }

.modelsSelectBox input[type=radio] {
  margin-right: 5px; }

.impactComments {
  padding-top: 30px; }

.impactComments textarea {
  float: right;
  border: 1px solid #888888;
  font-size: 16px;
  width: 540px;
  padding: 5px;
  height: 100px; }

.impactComments h3 {
  font-size: 22px;
  margin-bottom: 13px;
  color: #404040; }

.staffmembers-list, .staffmembers-photos {
  font-size: 1.1em;
  margin: 1em 0; }

.staffmembers-role {
  font-size: 0.9em; }

.staffmembers-photos {
  list-style-type: none;
  margin-top: 0; }
  .staffmembers-photos li {
    background-image: none;
    float: left;
    text-align: center;
    display: block;
    width: 180px;
    margin: 0;
    margin-top: 2em;
    padding: 0; }
  .staffmembers-photos li.clear-row {
    clear: left; }
  .staffmembers-photos img {
    margin: 0.5em 0;
    max-width: 100px;
    max-height: 100px;
    height: auto; }

.staffmembers-list {
  line-height: 150%; }

.descriptionContainer {
  height: 68px; }

.descriptionContainer .staffmembers-organization {
  font-size: 9pt; }

.alignright {
  float: right;
  margin: 0 0 0 30px; }

.alignleft {
  float: left;
  margin: 0 30px 0 0; }

* html .clearfix, * html .c-Headline {
  height: 1%; }

fieldset {
  border: 0;
  padding: 0;
  margin: 0; }

#invalidFieldsList li {
  margin-bottom: 10px;
  font-weight: 700; }

.seeAllActivities {
  text-align: right; }

.no-bottom-margin, ul.no-bottom-margin, ol.no-bottom-margin {
  margin-bottom: 0 !important; }

.info.subscribe img {
  vertical-align: bottom; }

.header-back-to {
  display: none; }

.taglib-header {
  border-bottom: 0; }

.toplinks li {
  list-style: none; }

.hashlink {
  display: none; }

#freeOutputPhysical {
  padding-bottom: 20px; }

.taglib-icon-help span.hide-accessible {
  font-size: 10pt;
  font-weight: normal;
  margin-left: 10px;
  color: #a9a9a9; }

.taglib-header h3 {
  margin-top: 20px !important; }

.js .controls-hidden .lfr-meta-actions {
  display: block; }

.role-indicator div {
  float: left;
  margin-right: 15px; }

fieldset.fieldset .control-group {
  float: left;
  margin-right: 50px; }

.teamBox .teamMemberListing li {
  line-height: 10px;
  margin-bottom: 5px;
  background-size: 20px;
  background: #FFF url("/images/icon_question-x.png") no-repeat 99%;
  list-style: none;
  margin-left: 0; }

.teamBox .teamMemberAdd input {
  background: #FFF url("/images/contest-icon-cplus-orange.png") no-repeat;
  background-size: 20px;
  background-position: 98% 50%; }

.teamBox .teamMemberListing .name {
  padding-left: 7px;
  vertical-align: 11px; }

.ui-autocomplete.ui-menu {
  z-index: 1100; }

li.ui-menu-item {
  border-bottom: 1px solid #ccc;
  background: #fff url("/images/contest-icon-cplus-blue.png");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98% 50%;
  padding-left: 10px; }

li.ui-menu-item .ui-state-focus {
  background: #FFF url("/images/contest-icon-cplus-green.png");
  border: 1px solid #ccc;
  border-radius: 0;
  color: #000;
  background-repeat: no-repeat;
  background-position: 98% 50%;
  padding-left: 10px; }
